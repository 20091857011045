import Vue from "vue";
import {
  message,
  ConfigProvider,
  DatePicker,
  Dropdown,
  Icon,
  Menu,
  Modal,
  Pagination,
  Select,
  Switch,
  Upload
} from "ant-design-vue";
import "./index.scss";

Vue.use(ConfigProvider);
Vue.use(DatePicker);
Vue.use(Dropdown);
Vue.use(Icon);
Vue.use(Menu);
Vue.use(Modal);
Vue.use(Pagination);
Vue.use(Select);
Vue.use(Switch);
Vue.use(Upload);

message.config({
  top: "25vh",
  maxCount: 3,
  duration: 2
});

Vue.prototype.$message = message;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$info = Modal.info;
