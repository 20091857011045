import VConsole from "vconsole";

const isTest =
  location.href.includes("test.angrymiao.com") ||
  !location.href.includes(".angrymiao.com");

if (isTest || new URL(window.location.href).searchParams.get("test"))
  new VConsole({ theme: "dark" });

const base = `/api`;
const fcBase = `https://fc.angrymiao.com/h5/${isTest ? "test" : "prod"}`;
const authBase = `https://auth${isTest ? "-test" : ""}.angrymiao.com/api`;
const mallBase = `https://mall${isTest ? "-test" : ""}.angrymiao.com/api`;
const coinBase = `https://miao-coin${isTest ? "-test" : ""}.angrymiao.com/api`;
const commentBase = `https://comment${isTest ? "-test" : ""}.angrymiao.com/api`;
const verifyBase = `https://verify${isTest ? "-test" : ""}.angrymiao.com/api`;
const diyBase = `https://diy${isTest ? "-test" : ""}.angrymiao.com/api`;
const aiBase = `https://ai${isTest ? "-test" : ""}.angrymiao.com/api`;

export default {
  isTest,
  auth: `https://auth${isTest ? "-test" : ""}.angrymiao.com/`, // 登录页面
  authRefresh: `https://auth${isTest ? "-test" : ""}.angrymiao.com/refresh/`, // 登录页面
  authRefreshToken: `${authBase}/passport/refresh-token`, // 刷新登录 token
  authProfile: `${authBase}/profile`, // 获取用户 profile
  authOauth2Share: `${authBase}/share/oauth2-share-sign`, // 第三方分享授权
  daoAdmin: `https://miao-coin-admin${isTest ? "-test" : ""}.angrymiao.com/`, // DAO 管理页面

  AMHatsuNumber: `${mallBase}/nft-number/info`, // AM HATSU 选号
  order: `${mallBase}/order`, // 订单
  AMAfaCustom: `${mallBase}/keyboard-custom`, // AM AFA 定制
  AMAfaCustomX: `${mallBase}/keyboard-custom/x`, // AM AFA 定制
  draw: `${mallBase}/draw`, // 抽奖

  uploadSign: `${coinBase}/upload/sign`, // OSS 登录
  bucketSign: `${coinBase}/private-bucket/sign`, // 访问私有bucket内容签名
  task: `${coinBase}/task`, // 任务
  taskX: `${coinBase}/task/x/`, // 任务
  taskCategory: `${coinBase}/task-category`, // 任务类型
  taskTag: `${coinBase}/task-tag`, // 任务标签
  taskTagTips: `${coinBase}/task/tag-tips`, // 任务标签提示
  taskTagGroup: `${coinBase}/task-tag/group/`, // 任务标签用户组
  chain: `${coinBase}/chain`, // 任务链
  taskVote: `${coinBase}/vote`, // 任务投票
  comment: `${coinBase}/comment`, // 任务评论
  reward: `${coinBase}/reward`, // 任务奖励
  balance: `${coinBase}/balance-count`, // 余额
  balanceHistory: `${coinBase}/balance`, // 奖励余额记录
  taskRecord: `${coinBase}/task-record`, // 任务记录
  permission: `${coinBase}/permission/current-user`, // 权限
  user: `${coinBase}/user`, // 用户
  group: `${coinBase}/group`, // 用户组
  groupUsers: `${coinBase}/group/users`, // 用户组用户
  groupCurrentUser: `${coinBase}/group/current-user`, // 当前用户用户组
  notification: `${coinBase}/notification`, // 系统通知
  notificationMark: `${coinBase}/notification-log/mark`, // 系统通知已读
  walletTransfer: `${coinBase}/wallet/transfer`, // 钱包转账

  commentLike: `${commentBase}/like/comment-like`, // 评论点赞
  commentUnlike: `${commentBase}/like/comment-unlike`, // 评论取消点赞
  commentLikeCheck: `${commentBase}/like/comment-check`, // 评论点赞检查

  check: `${verifyBase}/device-check`, // 查询设备信息

  cyberbladeApp: `${diyBase}/version-manager/newest-version-tws`, // 查询 TWS 版本信息
  historyVersion: `${diyBase}/version-manager/history-version`, // app 历史版本

  settings: `https://cdn-www.angrymiao.com/${
    isTest ? "test_config" : "config"
  }/settings.json`, // 官网配置文件

  checkIP: `${base}/geo-ip/check`, // IP 归属地查询

  request: `${fcBase}/product-user-collection-save`, // 想要
  requestInfo: `${fcBase}/product-user-collection-get`, // 想要信息
  requestCount: `${fcBase}/product-user-collection-stat`, // 统计想要数量

  chat: `https://open-ai-test-common-tjsahfxqay.ap-southeast-1.fcapp.run/ask-me-anything`, // 聊天
  chatStream: `${aiBase}/chat`, // 聊天
  dataFactory: `${aiBase}/data-factory/public-data-factory/`, // 聊天
  asr: `https://open-ai-test-common-tjsahfxqay.ap-southeast-1.fcapp.run/asr`, // 音频转文字
  signInPhoneCode: `${aiBase}/auth/sign-in/phone-code/`, // 登录短信验证
  signInPhone: `${aiBase}/auth/sign-in/phone/` // 短信登录
};
