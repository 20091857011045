









































































































import Vue, { PropType } from "vue";
import { mapState, mapGetters } from "vuex";
import { dispatch, Comment, Task } from "@/store";

export default Vue.extend({
  name: "VTaskCommentItem",
  props: {
    taskId: Number,
    task: Object as PropType<Task>,
    comment: Object as PropType<Comment>,
    likes: Object
  },
  data() {
    return {
      key: new Date().getTime(),
      coin: null as any,
      isChange: false
    };
  },
  computed: {
    ...mapState({ user: "user" }),
    ...mapState({ rewards: "rewards" }),
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      images: "images"
    }),
    ...mapGetters(["allow"])
  },
  methods: {
    onTimeClick() {
      window.location.hash = "task-comment-" + this.comment.id;
      window.open(window.location.href, "_blank");
    },
    onReply() {
      this.comment.reply_count = (this.comment.reply_count || 0) + 1;
      this.key = new Date().getTime() + (this.comment.id || 0);
    },
    onDelete() {
      this.$confirm({
        title: this.$t("task.deleteConfirm"),
        centered: true,
        okText: "" + this.$t("task.ok"),
        cancelText: "" + this.$t("task.cancel"),
        onOk: () => {
          dispatch.commentsDelete(this.comment).then(() => {
            this.key = new Date().getTime() + (this.comment.id || 0);
            this.$message.info("" + this.$t("task.deleteSuccess"));
            this.$emit("change");
            dispatch.rewardsGetList({
              ...this.rewards.query,
              task_id: this.taskId,
              page_number: 1
            });
          });
        }
      });
    },
    onLike() {
      const id = Number(this.comment.id);
      if (!this.user.isSignIn) {
        dispatch.userSignIn();
      }
      (this.comment.is_like
        ? dispatch.commentsUnlike(id)
        : dispatch.commentsLike(id)
      ).then(res => {
        this.comment.like_count = res.count;
        this.comment.is_like = !this.comment.is_like;
        this.$emit("like");
      });
    },
    onCoinInput(coin: number) {
      this.coin = Math.floor(Math.abs(coin));
    },
    onCheck() {
      if (!this.coin || this.coin <= 0) {
        return;
      }
      (this.isChange
        ? dispatch.rewardsPatch({
            id: (this.comment.reward_info as any).id,
            task_id: this.taskId,
            number: Number(this.coin),
            comment_id: Number(this.comment.id)
          })
        : dispatch.rewardsPost({
            task_id: this.taskId,
            number: Number(this.coin),
            comment_id: Number(this.comment.id)
          })
      ).then(res => {
        this.$message.info("" + this.$t("task.markSuccess"));
        this.comment.reward_info = res;
        this.isChange = false;
        dispatch.rewardsGetList({
          ...this.rewards.query,
          task_id: this.taskId,
          page_number: 1
        });
      });
    },
    onEdit() {
      this.coin = this.comment.reward_info?.number || 0;
      this.isChange = true;
    }
  }
});
