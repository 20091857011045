/* eslint-disable */
import { importAll } from "../../utils";

const imagesContext = require.context("../../assets/images", true, /\.*.$/);
const images = importAll(imagesContext);
const ossDir = "https://cdn-www.angrymiao.com/";

export interface AssetsState {
  images: any;
  oss: any;
  url: any;
}

const state: AssetsState = {
  images,
  oss: {
    home_emptiness_charger: ossDir + "home/home_emptiness_charger.jpg",
    home_am_emptiness_phone_case:
      ossDir + "home/home_am_emptiness_phone_case.jpg",
    home_gold_paisley: ossDir + "home/home_gold_paisley.jpg",
    home_afa_blade: ossDir + "home/home_afa_blade.jpg",
    home_am_neon_80: ossDir + "home/home_am_neon_80.jpg",
    home_cb_back: ossDir + "home/home_cb_back.jpg",
    home_am_rgb_65: ossDir + "home/home_am_rgb_65.jpg",
    home_am_rgb_65_mobile: ossDir + "home/home_am_rgb_65_mobile.jpg",
    home_am_master_cn: ossDir + "home/home_am_master_cn.jpg",
    home_am_afa_r2_kv_en: ossDir + "home/home_am_afa_r2_kv_en.jpg",
    home_cosmo_killer_r2_kv_en: ossDir + "home/home_cosmo_killesr_r2_kv_en.jpg",
    home_cyberboard_r4_cn: ossDir + "home/home_cyberboard_r4_cn.jpg",
    home_cyberboard_r4_en: ossDir + "home/home_cyberboard_r4_en.jpg",
    home_cyberboard_r2_5: ossDir + "home/home_cyberboard_r2_5.jpg",
    home_cyberboard_r2_5_mobile:
      ossDir + "home/home_cyberboard_r2_5_mobile.jpg",
    home_am_65_less_en: ossDir + "home/home_am_65_less_en.jpg",
    home_am_65_less_mobile_en: ossDir + "home/home_am_65_less_mobile_en.png",
    home_cosmo_killer_en: ossDir + "home/home_cosmo_killer_en.jpg",
    home_cosmo_killer_mobile_en:
      ossDir + "home/home_cosmo_killer_mobile_en.jpg",
    home_cyberboard_typing_kit_kv_cn:
      ossDir + "home/home_cyberboard_typing_kit_kv_cn.png",
    home_cyberboard_typing_kit_kv_en:
      ossDir + "home/home_cyberboard_typing_kit_kv_en.jpg",
    home_diy_competition_cn: ossDir + "home/home_diy_competition_cn.jpg",
    home_diy_competition_en: ossDir + "home/home_diy_competition_en.jpg",
    home_diy_competition_mobile_cn:
      ossDir + "home/home_diy_competition_mobile_cn.jpg",
    home_diy_competition_mobile_en:
      ossDir + "home/home_diy_competition_mobile_en.jpg",
    home_diy_competition_more_cn:
      ossDir + "home/home_diy_competition_more_cn.png",
    home_diy_competition_more_en:
      ossDir + "home/home_diy_competition_more_en.png",
    home_tws_cn: ossDir + "home/home_tws_cn.png",
    home_tws_en: ossDir + "home/home_tws_en.png",
    home_tws_ja: ossDir + "home/home_tws_ja.png",
    home_tws_cn_mobile: ossDir + "home/home_tws_cn_mobile.png",
    home_tws_en_mobile: ossDir + "home/home_tws_en_mobile.png",
    home_tws_ja_mobile: ossDir + "home/home_tws_ja_mobile.png",
    home_tws_link: ossDir + "home/home_tws_link.png",
    home_terminal_cn: ossDir + "home/home_terminal_cn.jpg",
    home_terminal_en: ossDir + "home/home_terminal_en.jpg",
    home_terminal_cn_mobile: ossDir + "home/home_terminal_cn_mobile.jpg",
    home_terminal_en_mobile: ossDir + "home/home_terminal_en_mobile.jpg",
    home_terminal_link: ossDir + "home/home_terminal_link.png",
    home_cybermat_r2: ossDir + "home/home_cybermat_r2.mp4",
    home_cybermat_r2_playback: ossDir + "home/home_cybermat_r2_playback.jpg",
    home_cyberboard_r2: ossDir + "home/home_cyberboard_r2.mp4",
    home_cyberboard_r2_playback:
      ossDir + "home/home_cyberboard_r2_playback.jpg",
    home_banner: ossDir + "home/home_banner.png",
    home_cv: ossDir + "home/home_cv.mp4",
    home_cv_playback: ossDir + "home/home_cv_playback.svg",
    home_cv_playback_mobile: ossDir + "home/home_cv_playback_mobile.jpg",
    home_ergo: ossDir + "home/home_ergo.jpg",
    home_ergo_mobile: ossDir + "home/home_ergo_mobile.jpg",
    home_cyberboard_r3: ossDir + "home/home_cyberboard_r3.jpg",
    home_cyberboard_r3_mobile: ossDir + "home/home_cyberboard_r3_mobile.jpg",

    products_am_afa_blade: ossDir + "products/products_am_afa_blade.png",
    products_am_neon_80: ossDir + "products/products_am_neon_80.png",
    products_am_rgb_65: ossDir + "products/products_am_rgb_65.png",
    products_am_emptiness_charger:
      ossDir + "products/products_am_emptiness_charger.png",
    products_icy_silver_switch_pro_v2:
      ossDir + "products/products_icy_silver_switch_pro_v2.png",
    products_icy_silver_switch_pro_v2_2:
      ossDir + "products/products_icy_silver_switch_pro_v2_2.png",
    products_am_relic_80_og: ossDir + "products/products_am_relic_80_og.png",
    products_cosmo_killer_dune:
      ossDir + "products/products_cosmo_killer_dune.png",
    products_am_relic_80: ossDir + "products/products_am_relic_80.png",
    products_am_emptiness_phonecase:
      ossDir + "products/products_am_emptiness_phonecase.png",
    products_cybercharge_35: ossDir + "products/products_cybercharge_35.png",
    products_cyberboard_r4: ossDir + "products/products_cyberboard_r4.png",
    products_cyberboard_r4_en:
      ossDir + "products/products_cyberboard_r4_en.png",
    products_icy_silver_switch_pro:
      ossDir + "products/products_icy_silver_switch_pro.png",
    products_cosmo_killer_r2: ossDir + "products/products_cosmo_killer_r2.png",
    products_am_afa_r2: ossDir + "products/products_am_afa_r2.png",
    products_carpio_am_editon:
      ossDir + "products/products_carpio_am_editon.png",
    products_am_65_less_fading:
      ossDir + "products/products_am_65_less_fading.png",
    products_am_65_less: ossDir + "products/products_am_65_less.png",
    products_am_65_less_r2: ossDir + "products/products_am_65_less_r2.png",
    products_cosmo_killer: ossDir + "products/products_cosmo_killer.png",
    products_am_master: ossDir + "products/products_am_master.png",
    products_am_afa: ossDir + "products/products_am_afa.png",
    products_hover: ossDir + "products/products_hover.png",
    products_cyberboard_terminal:
      ossDir + "products/products_cyberboard_terminal.png",
    products_cyberboard_gb: ossDir + "products/products_cyberboard_gb.png",
    products_cyberboard_glacier_r2:
      ossDir + "products/products_cyberboard_glacier_r2.png",
    products_cyberboard_r2: ossDir + "products/products_cyberboard_r2.png",
    products_cyberboard_r3: ossDir + "products/products_cyberboard_r3.png",
    products_emergence_keycaps:
      ossDir + "products/products_emergence_keycaps.png",
    products_icy_silver_switch:
      ossDir + "products/products_icy_silver_switch.png",
    products_glacier_keycap_set:
      ossDir + "products/products_glacier_keycap_set.png",
    products_angry_miao_021c: ossDir + "products/products_angry_miao_021c.png",
    products_time_machine_02: ossDir + "products/products_time_machine_02.png",
    products_the_chimney_cat: ossDir + "products/products_the_chimney_cat.png",
    products_am_hatsu: ossDir + "products/products_am_hatsu.png",
    products_am_hatsu_battleship:
      ossDir + "products/products_am_hatsu_battleship.png",
    products_cyberblade: ossDir + "products/products_cyberblade.png",
    products_cyberboard: ossDir + "products/products_cyberboard.png",
    products_cyberboard_xmas: ossDir + "products/products_cyberboard_xmas.png",
    products_cyberboard_typing_kit:
      ossDir + "products/products_cyberboard_typing_kit.png",
    products_cybercharge: ossDir + "products/products_cybercharge.png",
    products_cybermat: ossDir + "products/products_cybermat.png",
    products_cybermat_r2: ossDir + "products/products_cybermat_r2.png",
    products_dancing_cat: ossDir + "products/products_dancing_cat.png",
    products_amtoys: ossDir + "products/products_amtoys.png",
    products_back_to_the_future:
      ossDir + "products/products_back_to_the_future.png",
    products_cybercoin: ossDir + "products/products_cybercoin.png",
    products_cyberswitch: ossDir + "products/products_cyberswitch.png",
    products_ext: ossDir + "products/products_ext.png",
    products_time_machine: ossDir + "products/products_time_machine.png",

    black_diamond_75_bg: ossDir + "verify/black_diamond_75_bg.png",
    black_diamond_75_sn: ossDir + "verify/black_diamond_75_sn.png",
    verify_am_65_less_bg: ossDir + "verify/verify_am_65_less_bg.png",
    verify_am_65_less_sn: ossDir + "verify/verify_am_65_less_sn.png",
    verify_cyberboard_bg: ossDir + "verify/verify_cyberboard_bg.png",
    verify_cyberboard_sn: ossDir + "verify/verify_cyberboard_sn.png",
    verify_cyberblade_bg: ossDir + "verify/verify_cyberblade_bg.png",
    verify_am_hatsu_bg: ossDir + "verify/verify_am_hatsu_bg.png",
    verify_am_hatsu_sn: ossDir + "verify/verify_am_hatsu_sn.png",
    verify_am_afa_bg: ossDir + "verify/verify_am_afa_bg.png",
    verify_am_afa_sn: ossDir + "verify/verify_am_afa_sn.png",
    verify_cybermat_bg: ossDir + "verify/verify_cybermat_bg.png",
    verify_cybermat_sn: ossDir + "verify/verify_cybermat_sn.png",
    verify_loading: ossDir + "verify/verify_loading.png",

    draw_bg: ossDir + "draw/draw_bg.jpg",
    draw_next: ossDir + "draw/draw_next.png",
    draw_am_afa: ossDir + "draw/draw_am_afa.png",
    draw_am_65_less: ossDir + "draw/draw_am_65_less.png",
    draw_cyberboard_r4_vision: ossDir + "draw/draw_cyberboard_r4_vision.png",

    am_carpio_g2_kv_cn: ossDir + "am_carpio_g2/am_carpio_g2_kv_cn.jpg",
    am_carpio_g2_kv_en: ossDir + "am_carpio_g2/am_carpio_g2_kv_en.jpg",
    am_carpio_g2_kv_mobile_cn:
      ossDir + "am_carpio_g2/am_carpio_g2_kv_mobile_cn.jpg",
    am_carpio_g2_kv_mobile_en:
      ossDir + "am_carpio_g2/am_carpio_g2_kv_mobile_en.jpg",
    am_carpio_g2_01: ossDir + "am_carpio_g2/am_carpio_g2_01.jpg",
    am_carpio_g2_02: ossDir + "am_carpio_g2/am_carpio_g2_02.jpg",
    am_carpio_g2_03: ossDir + "am_carpio_g2/am_carpio_g2_03.png",
    am_carpio_g2_04: ossDir + "am_carpio_g2/am_carpio_g2_04.jpg",
    am_carpio_g2_05: ossDir + "am_carpio_g2/am_carpio_g2_05.jpg",
    am_carpio_g2_06: ossDir + "am_carpio_g2/am_carpio_g2_06.jpg",
    am_carpio_g2_07: ossDir + "am_carpio_g2/am_carpio_g2_07.jpg",
    am_carpio_g2_08: ossDir + "am_carpio_g2/am_carpio_g2_08.mp4",

    am_emptiness_phonecase_16_kv_cn:
      ossDir + "am_emptiness_phonecase_16/am_emptiness_phonecase_16_kv_cn.png",
    am_emptiness_phonecase_16_kv_en:
      ossDir + "am_emptiness_phonecase_16/am_emptiness_phonecase_16_kv_en.jpg",
    am_emptiness_phonecase_16_kv_cn_mobile:
      ossDir +
      "am_emptiness_phonecase_16/am_emptiness_phonecase_16_kv_cn_mobile.png",
    am_emptiness_phonecase_16_kv_en_mobile:
      ossDir +
      "am_emptiness_phonecase_16/am_emptiness_phonecase_16_kv_en_mobile.jpg",
    am_emptiness_phonecase_16_01:
      ossDir + "am_emptiness_phonecase_16/am_emptiness_phonecase_16_01.jpg",
    am_emptiness_phonecase_16_02:
      ossDir + "am_emptiness_phonecase_16/am_emptiness_phonecase_16_02.jpg",
    am_emptiness_phonecase_16_03:
      ossDir + "am_emptiness_phonecase_16/am_emptiness_phonecase_16_03.jpg",
    am_emptiness_phonecase_16_04:
      ossDir + "am_emptiness_phonecase_16/am_emptiness_phonecase_16_04.jpg",
    am_emptiness_phonecase_16_05:
      ossDir + "am_emptiness_phonecase_16/am_emptiness_phonecase_16_05.jpg",
    am_emptiness_phonecase_16_06:
      ossDir + "am_emptiness_phonecase_16/am_emptiness_phonecase_16_06.png",
    am_emptiness_phonecase_16_06_en:
      ossDir + "am_emptiness_phonecase_16/am_emptiness_phonecase_16_06_en.jpg",
    am_emptiness_phonecase_16_06_mobile:
      ossDir +
      "am_emptiness_phonecase_16/am_emptiness_phonecase_16_06_mobile.png",
    am_emptiness_phonecase_16_06_mobile_en:
      ossDir +
      "am_emptiness_phonecase_16/am_emptiness_phonecase_16_06_mobile_en.jpg",
    am_emptiness_phonecase_16_07:
      ossDir + "am_emptiness_phonecase_16/am_emptiness_phonecase_16_07.jpg",
    am_emptiness_phonecase_16_08:
      ossDir + "am_emptiness_phonecase_16/am_emptiness_phonecase_16_08.jpg",
    am_emptiness_phonecase_16_09:
      ossDir + "am_emptiness_phonecase_16/am_emptiness_phonecase_16_09.jpg",
    am_emptiness_phonecase_16_10:
      ossDir + "am_emptiness_phonecase_16/am_emptiness_phonecase_16_10.jpg",
    am_emptiness_phonecase_16_11:
      ossDir + "am_emptiness_phonecase_16/am_emptiness_phonecase_16_11.jpg",
    am_emptiness_phonecase_16_12:
      ossDir + "am_emptiness_phonecase_16/am_emptiness_phonecase_16_12.jpg",

    cyberboard_gold_paisley_kv_cn:
      ossDir + "cyberboard_gold_paisley/cyberboard_gold_paisley_kv_cn.jpg",
    cyberboard_gold_paisley_kv_cn_mobile:
      ossDir +
      "cyberboard_gold_paisley/cyberboard_gold_paisley_kv_cn_mobile.png",
    cyberboard_gold_paisley_kv_en:
      ossDir + "cyberboard_gold_paisley/cyberboard_gold_paisley_kv_en.jpg",
    cyberboard_gold_paisley_kv_en_mobile:
      ossDir +
      "cyberboard_gold_paisley/cyberboard_gold_paisley_kv_en_mobile.png",
    cyberboard_gold_paisley_01:
      ossDir + "cyberboard_gold_paisley/cyberboard_gold_paisley_01.mp4",
    cyberboard_gold_paisley_02:
      ossDir + "cyberboard_gold_paisley/cyberboard_gold_paisley_02.jpg",
    cyberboard_gold_paisley_03:
      ossDir + "cyberboard_gold_paisley/cyberboard_gold_paisley_03.jpg",
    cyberboard_gold_paisley_04:
      ossDir + "cyberboard_gold_paisley/cyberboard_gold_paisley_04.jpg",
    cyberboard_gold_paisley_05:
      ossDir + "cyberboard_gold_paisley/cyberboard_gold_paisley_05.jpg",
    cyberboard_gold_paisley_06:
      ossDir + "cyberboard_gold_paisley/cyberboard_gold_paisley_06.jpg",
    cyberboard_gold_paisley_07:
      ossDir + "cyberboard_gold_paisley/cyberboard_gold_paisley_07.jpg",
    cyberboard_gold_paisley_08:
      ossDir + "cyberboard_gold_paisley/cyberboard_gold_paisley_08.jpg",
    cyberboard_gold_paisley_09:
      ossDir + "cyberboard_gold_paisley/cyberboard_gold_paisley_09.jpg",
    cyberboard_gold_paisley_10:
      ossDir + "cyberboard_gold_paisley/cyberboard_gold_paisley_10.jpg",
    cyberboard_gold_paisley_11:
      ossDir + "cyberboard_gold_paisley/cyberboard_gold_paisley_11.jpg",

    the_novel_project_kv: ossDir + "the_novel_project/the_novel_project_kv.png",
    the_novel_project_01: ossDir + "the_novel_project/the_novel_project_01.jpg",
    the_novel_project_02: ossDir + "the_novel_project/the_novel_project_02.png",
    the_novel_project_03: ossDir + "the_novel_project/the_novel_project_03.png",
    the_novel_project_03_en:
      ossDir + "the_novel_project/the_novel_project_03_en.png",
    the_novel_project_04: ossDir + "the_novel_project/the_novel_project_04.png",
    the_novel_project_04_en:
      ossDir + "the_novel_project/the_novel_project_04_en.png",
    the_novel_project_05: ossDir + "the_novel_project/the_novel_project_05.png",
    the_novel_project_06: ossDir + "the_novel_project/the_novel_project_06.png",
    the_novel_project_07: ossDir + "the_novel_project/the_novel_project_07.png",
    the_novel_project_07_en:
      ossDir + "the_novel_project/the_novel_project_07_en.png",
    the_novel_project_08: ossDir + "the_novel_project/the_novel_project_08.jpg",
    the_novel_join_01: ossDir + "the_novel_project/the_novel_join_01.png",
    the_novel_join_02: ossDir + "the_novel_project/the_novel_join_02.png",
    the_novel_join_03: ossDir + "the_novel_project/the_novel_join_03.png",
    the_novel_join_04: ossDir + "the_novel_project/the_novel_join_04.png",
    the_novel_project_template_psd:
      ossDir + "the_novel_project/the_novel_project_template_psd.zip",
    the_novel_project_template_01:
      ossDir + "the_novel_project/the_novel_project_template_01.png",
    the_novel_project_template_02:
      ossDir + "the_novel_project/the_novel_project_template_02.png",
    the_novel_project_template_03:
      ossDir + "the_novel_project/the_novel_project_template_03.png",
    the_novel_project_template_04:
      ossDir + "the_novel_project/the_novel_project_template_04.png",
    the_novel_project_template_05:
      ossDir + "the_novel_project/the_novel_project_template_05.png",
    the_novel_project_cyberboard_psd:
      ossDir + "the_novel_project/the_novel_project_cyberboard_psd.zip",
    the_novel_project_cyberboard_01:
      ossDir + "the_novel_project/the_novel_project_cyberboard_01.png",
    the_novel_project_cyberboard_02:
      ossDir + "the_novel_project/the_novel_project_cyberboard_02.png",
    the_novel_project_cyberboard_03:
      ossDir + "the_novel_project/the_novel_project_cyberboard_03.png",

    am_afa_blade_kv_cn: ossDir + "am_afa_blade/am_afa_blade_kv_cn.png",
    am_afa_blade_kv_cn_mobile:
      ossDir + "am_afa_blade/am_afa_blade_kv_cn_mobile.png",
    am_afa_blade_kv_en: ossDir + "am_afa_blade/am_afa_blade_kv_en.png",
    am_afa_blade_kv_en_mobile:
      ossDir + "am_afa_blade/am_afa_blade_kv_en_mobile.png",
    am_afa_blade_01: ossDir + "am_afa_blade/am_afa_blade_01.jpg",
    am_afa_blade_02: ossDir + "am_afa_blade/am_afa_blade_02.jpg",
    am_afa_blade_03: ossDir + "am_afa_blade/am_afa_blade_03.jpg",
    am_afa_blade_04: ossDir + "am_afa_blade/am_afa_blade_04.jpg",
    am_afa_blade_05: ossDir + "am_afa_blade/am_afa_blade_05.jpg",
    am_afa_blade_06: ossDir + "am_afa_blade/am_afa_blade_06.jpg",
    am_afa_blade_07: ossDir + "am_afa_blade/am_afa_blade_07.jpg",
    am_afa_blade_08: ossDir + "am_afa_blade/am_afa_blade_08.png",
    am_afa_blade_09: ossDir + "am_afa_blade/am_afa_blade_09.mp4",
    am_afa_blade_09_en: ossDir + "am_afa_blade/am_afa_blade_09_en.mp4",
    am_afa_blade_10: ossDir + "am_afa_blade/am_afa_blade_10.jpg",
    am_afa_blade_11: ossDir + "am_afa_blade/am_afa_blade_11.jpg",
    am_afa_blade_12: ossDir + "am_afa_blade/am_afa_blade_12.jpg",
    am_afa_blade_13: ossDir + "am_afa_blade/am_afa_blade_13.jpg",
    am_afa_blade_14: ossDir + "am_afa_blade/am_afa_blade_14.jpg",
    am_afa_blade_15: ossDir + "am_afa_blade/am_afa_blade_15.jpg",
    am_afa_blade_16: ossDir + "am_afa_blade/am_afa_blade_16.jpg",
    am_afa_blade_17: ossDir + "am_afa_blade/am_afa_blade_17.jpg",

    am_neon_80_kv_cn: ossDir + "am_neon_80/am_neon_80_kv_cn.png",
    am_neon_80_kv_cn_mobile: ossDir + "am_neon_80/am_neon_80_kv_cn_mobile.png",
    am_neon_80_01: ossDir + "am_neon_80/am_neon_80_01.jpg",
    am_neon_80_02: ossDir + "am_neon_80/am_neon_80_02.jpg",
    am_neon_80_03: ossDir + "am_neon_80/am_neon_80_03.jpeg",
    am_neon_80_04: ossDir + "am_neon_80/am_neon_80_04.jpg",
    am_neon_80_05: ossDir + "am_neon_80/am_neon_80_05.jpg",
    am_neon_80_06: ossDir + "am_neon_80/am_neon_80_06.jpg",
    am_neon_80_07: ossDir + "am_neon_80/am_neon_80_07.jpg",
    am_neon_80_08: ossDir + "am_neon_80/am_neon_80_08.jpg",
    am_neon_80_09: ossDir + "am_neon_80/am_neon_80_09.jpg",
    am_neon_80_10: ossDir + "am_neon_80/am_neon_80_10.jpg",
    am_neon_80_11: ossDir + "am_neon_80/am_neon_80_11.jpeg",
    am_neon_80_12: ossDir + "am_neon_80/am_neon_80_12.jpg",
    am_neon_80_13: ossDir + "am_neon_80/am_neon_80_13.png",
    am_neon_80_14: ossDir + "am_neon_80/am_neon_80_14.jpg",
    am_neon_80_15: ossDir + "am_neon_80/am_neon_80_15.png",
    am_neon_80_15_mobile: ossDir + "am_neon_80/am_neon_80_15_mobile.jpg",
    am_neon_80_16: ossDir + "am_neon_80/am_neon_80_16.png",
    am_neon_80_16_mobile: ossDir + "am_neon_80/am_neon_80_16_mobile.jpg",
    am_neon_80_17: ossDir + "am_neon_80/am_neon_80_17.jpg",
    am_neon_80_18: ossDir + "am_neon_80/am_neon_80_18.png",
    am_neon_80_19: ossDir + "am_neon_80/am_neon_80_19.jpg",
    am_neon_80_20: ossDir + "am_neon_80/am_neon_80_20.jpg",
    am_neon_80_21: ossDir + "am_neon_80/am_neon_80_21.png",
    am_neon_80_22: ossDir + "am_neon_80/am_neon_80_22.jpg",
    am_neon_80_23: ossDir + "am_neon_80/am_neon_80_23.jpg",
    am_neon_80_24: ossDir + "am_neon_80/am_neon_80_24.png",
    am_neon_80_25: ossDir + "am_neon_80/am_neon_80_25.jpg",
    am_neon_80_26: ossDir + "am_neon_80/am_neon_80_26.jpg",
    am_neon_80_27: ossDir + "am_neon_80/am_neon_80_27.png",
    am_neon_80_video_01: ossDir + "am_neon_80/am_neon_80_video_01.mp4",
    am_neon_80_video_02: ossDir + "am_neon_80/am_neon_80_video_02.mp4",
    am_neon_80_video_03: ossDir + "am_neon_80/am_neon_80_video_03.mp4",
    am_neon_80_video_04: ossDir + "am_neon_80/am_neon_80_video_04.mp4",

    am_emptiness_charger_kv_cn:
      ossDir + "am_emptiness_charger/am_emptiness_charger_kv_cn.png",
    am_emptiness_charger_kv_mobile_cn:
      ossDir + "am_emptiness_charger/am_emptiness_charger_kv_mobile_cn.png",
    am_emptiness_charger_kv_en:
      ossDir + "am_emptiness_charger/am_emptiness_charger_kv_en.jpg",
    am_emptiness_charger_kv_mobile_en:
      ossDir + "am_emptiness_charger/am_emptiness_charger_kv_mobile_en.jpg",
    am_emptiness_charger_01:
      ossDir + "am_emptiness_charger/am_emptiness_charger_01.png",
    am_emptiness_charger_01_en:
      ossDir + "am_emptiness_charger/am_emptiness_charger_01_en.jpg",
    am_emptiness_charger_02:
      ossDir + "am_emptiness_charger/am_emptiness_charger_02.png",
    am_emptiness_charger_02_en:
      ossDir + "am_emptiness_charger/am_emptiness_charger_02_en.jpg",
    am_emptiness_charger_03:
      ossDir + "am_emptiness_charger/am_emptiness_charger_03.png",
    am_emptiness_charger_03_en:
      ossDir + "am_emptiness_charger/am_emptiness_charger_03_en.jpg",
    am_emptiness_charger_04:
      ossDir + "am_emptiness_charger/am_emptiness_charger_04.png",
    am_emptiness_charger_04_en:
      ossDir + "am_emptiness_charger/am_emptiness_charger_04_en.jpg",
    am_emptiness_charger_05:
      ossDir + "am_emptiness_charger/am_emptiness_charger_05.jpg",
    am_emptiness_charger_06:
      ossDir + "am_emptiness_charger/am_emptiness_charger_06.jpeg",
    am_emptiness_charger_07:
      ossDir + "am_emptiness_charger/am_emptiness_charger_07.png",
    am_emptiness_charger_08:
      ossDir + "am_emptiness_charger/am_emptiness_charger_08.jpg",
    am_emptiness_charger_09:
      ossDir + "am_emptiness_charger/am_emptiness_charger_09.jpg",
    am_emptiness_charger_10:
      ossDir + "am_emptiness_charger/am_emptiness_charger_10.jpg",
    am_emptiness_charger_11:
      ossDir + "am_emptiness_charger/am_emptiness_charger_11.jpg",
    am_emptiness_charger_12:
      ossDir + "am_emptiness_charger/am_emptiness_charger_12.png",
    am_emptiness_charger_13:
      ossDir + "am_emptiness_charger/am_emptiness_charger_13.png",
    am_emptiness_charger_14:
      ossDir + "am_emptiness_charger/am_emptiness_charger_14.jpg",
    am_emptiness_charger_15:
      ossDir + "am_emptiness_charger/am_emptiness_charger_15.jpg",
    am_emptiness_charger_16:
      ossDir + "am_emptiness_charger/am_emptiness_charger_16.jpg",
    am_emptiness_charger_17:
      ossDir + "am_emptiness_charger/am_emptiness_charger_17.jpg",
    am_emptiness_charger_18:
      ossDir + "am_emptiness_charger/am_emptiness_charger_18.jpg",

    am_rgb_65_kv_cn: ossDir + "am_rgb_65/am_rgb_65_kv_cn.png",
    am_rgb_65_kv_en: ossDir + "am_rgb_65/am_rgb_65_kv_en.jpg",
    am_rgb_65_kv_mobile_cn: ossDir + "am_rgb_65/am_rgb_65_kv_mobile_cn.jpeg",
    am_rgb_65_kv_mobile_en: ossDir + "am_rgb_65/am_rgb_65_kv_mobile_en.jpg",
    am_rgb_65_01: ossDir + "am_rgb_65/am_rgb_65_01.jpg",
    am_rgb_65_02: ossDir + "am_rgb_65/am_rgb_65_02.jpg",
    am_rgb_65_03: ossDir + "am_rgb_65/am_rgb_65_03.jpg",
    am_rgb_65_04: ossDir + "am_rgb_65/am_rgb_65_04.jpg",
    am_rgb_65_05: ossDir + "am_rgb_65/am_rgb_65_05.jpg",
    am_rgb_65_06: ossDir + "am_rgb_65/am_rgb_65_06.jpg",
    am_rgb_65_07: ossDir + "am_rgb_65/am_rgb_65_07.jpg",
    am_rgb_65_08: ossDir + "am_rgb_65/am_rgb_65_08.jpg",
    am_rgb_65_09: ossDir + "am_rgb_65/am_rgb_65_09.jpg",
    am_rgb_65_10: ossDir + "am_rgb_65/am_rgb_65_10.jpg",
    am_rgb_65_11: ossDir + "am_rgb_65/am_rgb_65_11.jpg",
    am_rgb_65_12: ossDir + "am_rgb_65/am_rgb_65_12.jpg",
    am_rgb_65_13: ossDir + "am_rgb_65/am_rgb_65_13.jpg",
    am_rgb_65_14: ossDir + "am_rgb_65/am_rgb_65_14.jpg",
    am_rgb_65_15: ossDir + "am_rgb_65/am_rgb_65_15.jpg",
    am_rgb_65_16: ossDir + "am_rgb_65/am_rgb_65_16.jpg",
    am_rgb_65_17: ossDir + "am_rgb_65/am_rgb_65_17.png",
    am_rgb_65_18: ossDir + "am_rgb_65/am_rgb_65_18.jpg",
    am_rgb_65_19: ossDir + "am_rgb_65/am_rgb_65_19.jpg",
    am_rgb_65_20: ossDir + "am_rgb_65/am_rgb_65_20.jpg",
    am_rgb_65_21: ossDir + "am_rgb_65/am_rgb_65_21.jpg",
    am_rgb_65_22: ossDir + "am_rgb_65/am_rgb_65_22.jpg",
    am_rgb_65_23: ossDir + "am_rgb_65/am_rgb_65_23.jpg",
    am_rgb_65_24: ossDir + "am_rgb_65/am_rgb_65_24.jpg",
    am_rgb_65_25: ossDir + "am_rgb_65/am_rgb_65_25.jpg",
    am_rgb_65_26: ossDir + "am_rgb_65/am_rgb_65_26.jpg",
    am_rgb_65_27: ossDir + "am_rgb_65/am_rgb_65_27.jpg",
    am_rgb_65_28: ossDir + "am_rgb_65/am_rgb_65_28.png",
    am_rgb_65_29: ossDir + "am_rgb_65/am_rgb_65_29.png",
    am_rgb_65_29_en: ossDir + "am_rgb_65/am_rgb_65_29_en.png",
    am_rgb_65_30: ossDir + "am_rgb_65/am_rgb_65_30.png",
    am_rgb_65_30_en: ossDir + "am_rgb_65/am_rgb_65_30_en.png",
    am_rgb_65_30_mobile: ossDir + "am_rgb_65/am_rgb_65_30_mobile.png",
    am_rgb_65_30_mobile_en: ossDir + "am_rgb_65/am_rgb_65_30_mobile_en.png",
    am_rgb_65_32: ossDir + "am_rgb_65/am_rgb_65_32.png",
    am_rgb_65_32_en: ossDir + "am_rgb_65/am_rgb_65_32_en.png",
    am_rgb_65_32_mobile: ossDir + "am_rgb_65/am_rgb_65_32_mobile.png",
    am_rgb_65_32_mobile_en: ossDir + "am_rgb_65/am_rgb_65_32_mobile_en.png",
    am_rgb_65_34: ossDir + "am_rgb_65/am_rgb_65_34.png",
    am_rgb_65_34_en: ossDir + "am_rgb_65/am_rgb_65_34_en.png",
    am_rgb_65_34_mobile: ossDir + "am_rgb_65/am_rgb_65_34_mobile.png",
    am_rgb_65_34_mobile_en: ossDir + "am_rgb_65/am_rgb_65_34_mobile_en.png",
    am_rgb_65_36: ossDir + "am_rgb_65/am_rgb_65_36.jpeg",
    am_rgb_65_37: ossDir + "am_rgb_65/am_rgb_65_37.jpeg",
    am_rgb_65_38: ossDir + "am_rgb_65/am_rgb_65_38.png",
    am_rgb_65_39: ossDir + "am_rgb_65/am_rgb_65_39.png",
    am_rgb_65_video_01: ossDir + "am_rgb_65/am_rgb_65_video_01.m4v",
    am_rgb_65_video_01_en: ossDir + "am_rgb_65/am_rgb_65_video_01_en.mp4",
    am_rgb_65_video_02: ossDir + "am_rgb_65/am_rgb_65_video_02.mp4",
    am_rgb_65_video_02_en: ossDir + "am_rgb_65/am_rgb_65_video_02_en.mp4",

    am_rgb_65_swiper_01: ossDir + "am_rgb_65/am_rgb_65_swiper_01.jpg",
    am_rgb_65_swiper_02: ossDir + "am_rgb_65/am_rgb_65_swiper_02.jpg",
    am_rgb_65_swiper_03: ossDir + "am_rgb_65/am_rgb_65_swiper_03.jpg",
    am_rgb_65_swiper_04: ossDir + "am_rgb_65/am_rgb_65_swiper_04.jpg",
    am_rgb_65_swiper_05: ossDir + "am_rgb_65/am_rgb_65_swiper_05.jpg",
    am_rgb_65_swiper_06: ossDir + "am_rgb_65/am_rgb_65_swiper_06.jpg",
    am_rgb_65_swiper_07: ossDir + "am_rgb_65/am_rgb_65_swiper_07.jpg",
    am_rgb_65_swiper_08: ossDir + "am_rgb_65/am_rgb_65_swiper_08.jpg",
    am_rgb_65_swiper_09: ossDir + "am_rgb_65/am_rgb_65_swiper_09.jpg",
    am_rgb_65_swiper_10: ossDir + "am_rgb_65/am_rgb_65_swiper_10.jpg",
    am_rgb_65_swiper_11: ossDir + "am_rgb_65/am_rgb_65_swiper_11.jpg",
    am_rgb_65_swiper_12: ossDir + "am_rgb_65/am_rgb_65_swiper_12.jpg",
    am_rgb_65_swiper_13: ossDir + "am_rgb_65/am_rgb_65_swiper_13.jpg",
    am_rgb_65_swiper_14: ossDir + "am_rgb_65/am_rgb_65_swiper_14.jpg",
    am_rgb_65_swiper_15: ossDir + "am_rgb_65/am_rgb_65_swiper_15.jpg",
    am_rgb_65_swiper_16: ossDir + "am_rgb_65/am_rgb_65_swiper_16.jpg",
    am_rgb_65_swiper_17: ossDir + "am_rgb_65/am_rgb_65_swiper_17.jpg",
    am_rgb_65_swiper_18: ossDir + "am_rgb_65/am_rgb_65_swiper_18.jpg",
    am_rgb_65_swiper_19: ossDir + "am_rgb_65/am_rgb_65_swiper_19.jpg",

    icy_silver_switch_pro_v2_kv_cn:
      ossDir + "icy_silver_switch_pro_v2/icy_silver_switch_pro_v2_kv_cn.jpg",
    icy_silver_switch_pro_v2_kv_en:
      ossDir + "icy_silver_switch_pro_v2/icy_silver_switch_pro_v2_kv_en.jpg",
    icy_silver_switch_pro_v2_kv_mobile_cn:
      ossDir +
      "icy_silver_switch_pro_v2/icy_silver_switch_pro_v2_kv_mobile_cn.png",
    icy_silver_switch_pro_v2_kv_mobile_en:
      ossDir +
      "icy_silver_switch_pro_v2/icy_silver_switch_pro_v2_kv_mobile_en.jpg",
    icy_silver_switch_pro_v2_01:
      ossDir + "icy_silver_switch_pro_v2/icy_silver_switch_pro_v2_01.png",
    icy_silver_switch_pro_v2_01_en:
      ossDir + "icy_silver_switch_pro_v2/icy_silver_switch_pro_v2_01_en.png",
    icy_silver_switch_pro_v2_02:
      ossDir + "icy_silver_switch_pro_v2/icy_silver_switch_pro_v2_02.jpg",
    icy_silver_switch_pro_v2_03:
      ossDir + "icy_silver_switch_pro_v2/icy_silver_switch_pro_v2_03.png",
    icy_silver_switch_pro_v2_04:
      ossDir + "icy_silver_switch_pro_v2/icy_silver_switch_pro_v2_04.mp4",
    icy_silver_switch_pro_v2_05:
      ossDir + "icy_silver_switch_pro_v2/icy_silver_switch_pro_v2_05.jpg",
    icy_silver_switch_pro_v2_06:
      ossDir + "icy_silver_switch_pro_v2/icy_silver_switch_pro_v2_06.jpg",
    icy_silver_switch_pro_v2_07:
      ossDir + "icy_silver_switch_pro_v2/icy_silver_switch_pro_v2_07.jpg",
    icy_silver_switch_pro_v2_08:
      ossDir + "icy_silver_switch_pro_v2/icy_silver_switch_pro_v2_08.jpg",
    icy_silver_switch_pro_v2_09:
      ossDir + "icy_silver_switch_pro_v2/icy_silver_switch_pro_v2_09.jpg",
    icy_silver_switch_pro_v2_10:
      ossDir + "icy_silver_switch_pro_v2/icy_silver_switch_pro_v2_10.jpg",
    icy_silver_switch_pro_v2_11:
      ossDir + "icy_silver_switch_pro_v2/icy_silver_switch_pro_v2_11.png",
    icy_silver_switch_pro_v2_12:
      ossDir + "icy_silver_switch_pro_v2/icy_silver_switch_pro_v2_12.jpg",

    am_relic_80_og_kv_cn: ossDir + "am_relic_80_og/am_relic_80_og_kv_cn.jpg",
    am_relic_80_og_kv_en: ossDir + "am_relic_80_og/am_relic_80_og_kv_en.jpg",
    am_relic_80_og_kv_cn_mobile:
      ossDir + "am_relic_80_og/am_relic_80_og_kv_cn_mobile.png",
    am_relic_80_og_kv_en_mobile:
      ossDir + "am_relic_80_og/am_relic_80_og_kv_en_mobile.jpg",
    am_relic_80_og_01: ossDir + "am_relic_80_og/am_relic_80_og_01.jpg",
    am_relic_80_og_01_all: ossDir + "am_relic_80_og/am_relic_80_og_01_all.jpg",
    am_relic_80_og_01_all_en:
      ossDir + "am_relic_80_og/am_relic_80_og_01_all_en.jpg",
    am_relic_80_og_02: ossDir + "am_relic_80_og/am_relic_80_og_02.jpg",
    am_relic_80_og_03: ossDir + "am_relic_80_og/am_relic_80_og_03.jpg",
    am_relic_80_og_04: ossDir + "am_relic_80_og/am_relic_80_og_04.jpg",
    am_relic_80_og_05: ossDir + "am_relic_80_og/am_relic_80_og_05.mp4",
    am_relic_80_og_05_en: ossDir + "am_relic_80_og/am_relic_80_og_05_en.mp4",
    am_relic_80_og_06: ossDir + "am_relic_80_og/am_relic_80_og_06.png",
    am_relic_80_og_07: ossDir + "am_relic_80_og/am_relic_80_og_07.jpg",
    am_relic_80_og_08: ossDir + "am_relic_80_og/am_relic_80_og_08.jpg",
    am_relic_80_og_09: ossDir + "am_relic_80_og/am_relic_80_og_09.jpg",
    am_relic_80_og_10: ossDir + "am_relic_80_og/am_relic_80_og_10.jpg",
    am_relic_80_og_11: ossDir + "am_relic_80_og/am_relic_80_og_11.jpg",
    am_relic_80_og_12: ossDir + "am_relic_80_og/am_relic_80_og_12.jpg",
    am_relic_80_og_13: ossDir + "am_relic_80_og/am_relic_80_og_13.jpg",
    am_relic_80_og_14: ossDir + "am_relic_80_og/am_relic_80_og_14.jpg",
    am_relic_80_og_15: ossDir + "am_relic_80_og/am_relic_80_og_15.jpg",
    am_relic_80_og_16: ossDir + "am_relic_80_og/am_relic_80_og_16.jpg",

    cosmo_killer_dune_01:
      ossDir + "cosmo_killer_dune/cosmo_killer_dune_01.JPEG",
    cosmo_killer_dune_02:
      ossDir + "cosmo_killer_dune/cosmo_killer_dune_02.JPEG",
    cosmo_killer_dune_03:
      ossDir + "cosmo_killer_dune/cosmo_killer_dune_03.JPEG",
    cosmo_killer_dune_04:
      ossDir + "cosmo_killer_dune/cosmo_killer_dune_04.JPEG",
    cosmo_killer_dune_05:
      ossDir + "cosmo_killer_dune/cosmo_killer_dune_05.JPEG",
    cosmo_killer_dune_06:
      ossDir + "cosmo_killer_dune/cosmo_killer_dune_06.JPEG",
    cosmo_killer_dune_07:
      ossDir + "cosmo_killer_dune/cosmo_killer_dune_07.JPEG",
    cosmo_killer_dune_08:
      ossDir + "cosmo_killer_dune/cosmo_killer_dune_08.JPEG",
    cosmo_killer_dune_09:
      ossDir + "cosmo_killer_dune/cosmo_killer_dune_09.jpeg",
    cosmo_killer_dune_10:
      ossDir + "cosmo_killer_dune/cosmo_killer_dune_10.jpeg",
    cosmo_killer_dune_kv_cn:
      ossDir + "cosmo_killer_dune/cosmo_killer_dune_kv_cn.jpg",
    cosmo_killer_dune_kv_en:
      ossDir + "cosmo_killer_dune/cosmo_killer_dune_kv_en.jpg",
    cosmo_killer_dune_kv_mobile_cn:
      ossDir + "cosmo_killer_dune/cosmo_killer_dune_kv_mobile_cn.jpg",
    cosmo_killer_dune_kv_mobile_en:
      ossDir + "cosmo_killer_dune/cosmo_killer_dune_kv_mobile_en.jpg",

    cyberboard_glacier_r2_kv_cn:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_kv_cn.png",
    cyberboard_glacier_r2_kv_en:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_kv_en.png",
    cyberboard_glacier_r2_kv_mobile_cn:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_kv_mobile_cn.png",
    cyberboard_glacier_r2_kv_mobile_en:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_kv_mobile_en.png",
    cyberboard_glacier_r2_01:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_01.png",
    cyberboard_glacier_r2_02:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_02.png",
    cyberboard_glacier_r2_03:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_03.png",
    cyberboard_glacier_r2_04:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_04.png",
    cyberboard_glacier_r2_05:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_05.jpg",
    cyberboard_glacier_r2_06_cn:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_06_cn.mp4",
    cyberboard_glacier_r2_06_en:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_06_en.mp4",
    cyberboard_glacier_r2_08:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_08.jpg",
    cyberboard_glacier_r2_09:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_09.png",
    cyberboard_glacier_r2_10:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_10.jpg",
    cyberboard_glacier_r2_11:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_11.png",
    cyberboard_glacier_r2_12:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_12.jpg",
    cyberboard_glacier_r2_13_cn:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_13_cn.jpg",
    cyberboard_glacier_r2_13_en:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_13_en.jpg",
    cyberboard_glacier_r2_list_01:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_list_01.png",
    cyberboard_glacier_r2_list_02:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_list_02.png",
    cyberboard_glacier_r2_list_03:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_list_03.jpg",
    cyberboard_glacier_r2_list_04:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_list_04.jpg",
    cyberboard_glacier_r2_list_05:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_list_05.jpg",
    cyberboard_glacier_r2_list_06:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_list_06.jpg",
    cyberboard_glacier_r2_list_07:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_list_07.jpg",
    cyberboard_glacier_r2_list_08:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_list_08.jpg",
    cyberboard_glacier_r2_swiper_01:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_swiper_01.jpg",
    cyberboard_glacier_r2_swiper_02:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_swiper_02.jpg",
    cyberboard_glacier_r2_swiper_03:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_swiper_03.jpg",
    cyberboard_glacier_r2_swiper_04:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_swiper_04.jpg",
    cyberboard_glacier_r2_swiper_05:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_swiper_05.jpg",
    cyberboard_glacier_r2_swiper_06:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_swiper_06.jpg",
    cyberboard_glacier_r2_swiper_07:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_swiper_07.jpg",
    cyberboard_glacier_r2_swiper_08:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_swiper_08.jpg",
    cyberboard_glacier_r2_swiper_09:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_swiper_09.jpg",
    cyberboard_glacier_r2_swiper_10:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_swiper_10.jpg",
    cyberboard_glacier_r2_swiper_11:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_swiper_11.jpg",
    cyberboard_glacier_r2_swiper_12:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_swiper_12.jpg",
    cyberboard_glacier_r2_swiper_13:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_swiper_13.jpg",
    cyberboard_glacier_r2_swiper_14:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_swiper_14.jpg",
    cyberboard_glacier_r2_swiper_15:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_swiper_15.jpg",
    cyberboard_glacier_r2_swiper_16:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_swiper_16.jpg",
    cyberboard_glacier_r2_swiper_17:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_swiper_17.jpg",
    cyberboard_glacier_r2_swiper_18:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_swiper_18.jpg",
    cyberboard_glacier_r2_swiper_19:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_swiper_19.jpg",
    cyberboard_glacier_r2_swiper_20:
      ossDir + "cyberboard_glacier_r2/cyberboard_glacier_r2_swiper_20.jpg",

    am_65_less_r2_kv_cn: ossDir + "am_65_less_r2/am_65_less_r2_kv_cn.jpg",
    am_65_less_r2_kv_en: ossDir + "am_65_less_r2/am_65_less_r2_kv_en.jpg",
    am_65_less_r2_01: ossDir + "am_65_less_r2/am_65_less_r2_01.png",
    am_65_less_r2_02: ossDir + "am_65_less_r2/am_65_less_r2_02.mp4",
    am_65_less_r2_03: ossDir + "am_65_less_r2/am_65_less_r2_03.jpg",
    am_65_less_r2_04: ossDir + "am_65_less_r2/am_65_less_r2_04.png",
    am_65_less_r2_05: ossDir + "am_65_less_r2/am_65_less_r2_05.jpg",
    am_65_less_r2_06: ossDir + "am_65_less_r2/am_65_less_r2_06.jpg",
    am_65_less_r2_07: ossDir + "am_65_less_r2/am_65_less_r2_07.jpg",
    am_65_less_r2_08: ossDir + "am_65_less_r2/am_65_less_r2_08.png",
    am_65_less_r2_09: ossDir + "am_65_less_r2/am_65_less_r2_09.jpg",
    am_65_less_r2_10: ossDir + "am_65_less_r2/am_65_less_r2_10.jpg",
    am_65_less_r2_11: ossDir + "am_65_less_r2/am_65_less_r2_11.jpg",
    am_65_less_r2_12: ossDir + "am_65_less_r2/am_65_less_r2_12.png",
    am_65_less_r2_13: ossDir + "am_65_less_r2/am_65_less_r2_13.jpg",
    am_65_less_r2_14: ossDir + "am_65_less_r2/am_65_less_r2_14.jpg",
    am_65_less_r2_15: ossDir + "am_65_less_r2/am_65_less_r2_15.jpg",
    am_65_less_r2_16: ossDir + "am_65_less_r2/am_65_less_r2_16.jpg",
    am_65_less_r2_17: ossDir + "am_65_less_r2/am_65_less_r2_17.jpg",
    am_65_less_r2_18: ossDir + "am_65_less_r2/am_65_less_r2_18.mp4",
    am_65_less_r2_18_en: ossDir + "am_65_less_r2/am_65_less_r2_18_en.mp4",
    am_65_less_r2_19: ossDir + "am_65_less_r2/am_65_less_r2_19.jpg",
    am_65_less_r2_20: ossDir + "am_65_less_r2/am_65_less_r2_20.jpg",
    am_65_less_r2_21: ossDir + "am_65_less_r2/am_65_less_r2_21.jpg",
    am_65_less_r2_22: ossDir + "am_65_less_r2/am_65_less_r2_22.jpg",
    am_65_less_r2_23: ossDir + "am_65_less_r2/am_65_less_r2_23.jpg",
    am_65_less_r2_24: ossDir + "am_65_less_r2/am_65_less_r2_24.jpg",
    am_65_less_r2_25: ossDir + "am_65_less_r2/am_65_less_r2_25.jpg",
    am_65_less_r2_26: ossDir + "am_65_less_r2/am_65_less_r2_26.jpg",
    am_65_less_r2_27: ossDir + "am_65_less_r2/am_65_less_r2_27.jpg",
    am_65_less_r2_29: ossDir + "am_65_less_r2/am_65_less_r2_29.jpg",
    am_65_less_r2_28: ossDir + "am_65_less_r2/am_65_less_r2_28.mp4",
    am_65_less_r2_28_en: ossDir + "am_65_less_r2/am_65_less_r2_28_en.mp4",
    am_65_less_r2_30: ossDir + "am_65_less_r2/am_65_less_r2_30.jpg",
    am_65_less_r2_31: ossDir + "am_65_less_r2/am_65_less_r2_31.png",
    am_65_less_r2_31_en: ossDir + "am_65_less_r2/am_65_less_r2_31_en.png",
    am_65_less_r2_32: ossDir + "am_65_less_r2/am_65_less_r2_32.jpg",
    am_65_less_r2_33: ossDir + "am_65_less_r2/am_65_less_r2_33.jpg",
    am_65_less_r2_34: ossDir + "am_65_less_r2/am_65_less_r2_34.png",
    am_65_less_r2_35: ossDir + "am_65_less_r2/am_65_less_r2_35.png",
    am_65_less_r2_36: ossDir + "am_65_less_r2/am_65_less_r2_36.png",
    am_65_less_r2_37: ossDir + "am_65_less_r2/am_65_less_r2_37.jpg",
    am_65_less_r2_37_en: ossDir + "am_65_less_r2/am_65_less_r2_37_en.jpg",
    am_65_less_r2_38: ossDir + "am_65_less_r2/am_65_less_r2_38.jpg",
    am_65_less_r2_38_en: ossDir + "am_65_less_r2/am_65_less_r2_38_en.jpg",

    am_65_less_r2_swiper_01:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_01.jpg",
    am_65_less_r2_swiper_02:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_02.jpg",
    am_65_less_r2_swiper_03:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_03.jpg",
    am_65_less_r2_swiper_04:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_04.jpg",
    am_65_less_r2_swiper_05:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_05.jpg",
    am_65_less_r2_swiper_06:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_06.jpg",
    am_65_less_r2_swiper_07:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_07.jpg",
    am_65_less_r2_swiper_08:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_08.jpg",
    am_65_less_r2_swiper_09:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_09.jpg",
    am_65_less_r2_swiper_10:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_10.jpg",
    am_65_less_r2_swiper_11:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_11.jpg",
    am_65_less_r2_swiper_12:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_12.jpg",
    am_65_less_r2_swiper_13:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_13.jpg",
    am_65_less_r2_swiper_14:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_14.jpg",
    am_65_less_r2_swiper_15:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_15.jpg",
    am_65_less_r2_swiper_16:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_16.jpg",
    am_65_less_r2_swiper_17:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_17.jpg",
    am_65_less_r2_swiper_18:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_18.jpg",
    am_65_less_r2_swiper_19:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_19.jpg",
    am_65_less_r2_swiper_20:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_20.jpg",
    am_65_less_r2_swiper_21:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_21.jpg",
    am_65_less_r2_swiper_22:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_22.jpg",
    am_65_less_r2_swiper_23:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_23.jpg",
    am_65_less_r2_swiper_24:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_24.jpg",
    am_65_less_r2_swiper_25:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_25.jpg",
    am_65_less_r2_swiper_26:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_26.jpg",
    am_65_less_r2_swiper_27:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_27.jpg",
    am_65_less_r2_swiper_29:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_29.jpg",
    am_65_less_r2_swiper_28:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_28.jpg",
    am_65_less_r2_swiper_30:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_30.jpg",
    am_65_less_r2_swiper_31:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_31.jpg",
    am_65_less_r2_swiper_32:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_32.jpg",
    am_65_less_r2_swiper_33:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_33.jpg",
    am_65_less_r2_swiper_34:
      ossDir + "am_65_less_r2/am_65_less_r2_swiper_34.jpg",

    am_relic_80_kv_cn: ossDir + "am_relic_80/am_relic_80_kv_cn.jpg",
    am_relic_80_kv_en: ossDir + "am_relic_80/am_relic_80_kv_en.jpg",
    am_relic_80_kv_mobile_cn:
      ossDir + "am_relic_80/am_relic_80_kv_mobile_cn.jpg",
    am_relic_80_kv_mobile_en:
      ossDir + "am_relic_80/am_relic_80_kv_mobile_en.jpg",
    am_relic_80_01: ossDir + "am_relic_80/am_relic_80_01.jpg",
    am_relic_80_02: ossDir + "am_relic_80/am_relic_80_02.jpg",
    am_relic_80_03: ossDir + "am_relic_80/am_relic_80_03.jpg",
    am_relic_80_04: ossDir + "am_relic_80/am_relic_80_04.jpg",
    am_relic_80_05: ossDir + "am_relic_80/am_relic_80_05.jpg",
    am_relic_80_06_cn: ossDir + "am_relic_80/am_relic_80_06_cn.jpg",
    am_relic_80_06_en: ossDir + "am_relic_80/am_relic_80_06_en.jpg",
    am_relic_80_07: ossDir + "am_relic_80/am_relic_80_07.jpeg",
    am_relic_80_08_01: ossDir + "am_relic_80/am_relic_80_08_01.jpg",
    am_relic_80_08_02: ossDir + "am_relic_80/am_relic_80_08_02.jpg",
    am_relic_80_08_03: ossDir + "am_relic_80/am_relic_80_08_03.jpg",
    am_relic_80_08_04: ossDir + "am_relic_80/am_relic_80_08_04.jpg",
    am_relic_80_09_01: ossDir + "am_relic_80/am_relic_80_09_01.jpg",
    am_relic_80_09_02: ossDir + "am_relic_80/am_relic_80_09_02.jpg",
    am_relic_80_09_03: ossDir + "am_relic_80/am_relic_80_09_03.jpg",
    am_relic_80_09_04: ossDir + "am_relic_80/am_relic_80_09_04.jpg",
    am_relic_80_09_05: ossDir + "am_relic_80/am_relic_80_09_05.jpg",
    am_relic_80_09_06: ossDir + "am_relic_80/am_relic_80_09_06.jpg",
    am_relic_80_10_01: ossDir + "am_relic_80/am_relic_80_10_01.jpg",
    am_relic_80_10_02: ossDir + "am_relic_80/am_relic_80_10_02.jpg",
    am_relic_80_10_03: ossDir + "am_relic_80/am_relic_80_10_03.jpg",
    am_relic_80_10_04: ossDir + "am_relic_80/am_relic_80_10_04.jpg",
    am_relic_80_10_05: ossDir + "am_relic_80/am_relic_80_10_05.jpg",
    am_relic_80_10_06: ossDir + "am_relic_80/am_relic_80_10_06.jpg",
    am_relic_80_10_07: ossDir + "am_relic_80/am_relic_80_10_07.jpg",
    am_relic_80_10_08: ossDir + "am_relic_80/am_relic_80_10_08.jpg",
    am_relic_80_10_09: ossDir + "am_relic_80/am_relic_80_10_09.jpg",
    am_relic_80_11: ossDir + "am_relic_80/am_relic_80_11.png",
    am_relic_80_12: ossDir + "am_relic_80/am_relic_80_12.jpg",
    am_relic_80_13: ossDir + "am_relic_80/am_relic_80_13.jpg",
    am_relic_80_14: ossDir + "am_relic_80/am_relic_80_14.jpg",
    am_relic_80_15: ossDir + "am_relic_80/am_relic_80_15.jpg",
    am_relic_80_16: ossDir + "am_relic_80/am_relic_80_16.jpg",
    am_relic_80_17: ossDir + "am_relic_80/am_relic_80_17.jpg",
    am_relic_80_18: ossDir + "am_relic_80/am_relic_80_18.jpg",
    am_relic_80_19: ossDir + "am_relic_80/am_relic_80_19.jpg",
    am_relic_80_20: ossDir + "am_relic_80/am_relic_80_20.jpg",
    am_relic_80_21: ossDir + "am_relic_80/am_relic_80_21.jpg",
    am_relic_80_22: ossDir + "am_relic_80/am_relic_80_22.jpg",
    am_relic_80_23: ossDir + "am_relic_80/am_relic_80_23.jpg",
    am_relic_80_24: ossDir + "am_relic_80/am_relic_80_24.jpg",
    am_relic_80_25: ossDir + "am_relic_80/am_relic_80_25.jpg",
    am_relic_80_26: ossDir + "am_relic_80/am_relic_80_26.jpg",
    am_relic_80_27: ossDir + "am_relic_80/am_relic_80_27.png",
    am_relic_80_28: ossDir + "am_relic_80/am_relic_80_28.jpg",
    am_relic_80_29: ossDir + "am_relic_80/am_relic_80_29.jpg",
    am_relic_80_30_cn: ossDir + "am_relic_80/am_relic_80_30_cn.png",
    am_relic_80_30_en: ossDir + "am_relic_80/am_relic_80_30_en.png",

    am_emptiness_phonecase_kv_cn:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_kv_cn.jpg",
    am_emptiness_phonecase_kv_en:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_kv_en.jpg",
    am_emptiness_phonecase_kv_mobile_cn:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_kv_mobile_cn.jpg",
    am_emptiness_phonecase_kv_mobile_en:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_kv_mobile_en.jpg",
    am_emptiness_phonecase_01:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_01.png",
    am_emptiness_phonecase_02:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_02.png",
    am_emptiness_phonecase_03:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_03.png",
    am_emptiness_phonecase_04:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_04.png",
    am_emptiness_phonecase_05:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_05.png",
    am_emptiness_phonecase_06:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_06.png",
    am_emptiness_phonecase_07:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_07.png",
    am_emptiness_phonecase_07_en:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_07_en.png",
    am_emptiness_phonecase_08:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_08.png",
    am_emptiness_phonecase_09:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_09.png",
    am_emptiness_phonecase_10:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_10.png",
    am_emptiness_phonecase_11:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_11.jpg",
    am_emptiness_phonecase_swiper_01:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_swiper_01.jpeg",
    am_emptiness_phonecase_swiper_02:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_swiper_02.jpeg",
    am_emptiness_phonecase_swiper_03:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_swiper_03.jpeg",
    am_emptiness_phonecase_swiper_04:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_swiper_04.jpeg",
    am_emptiness_phonecase_swiper_05:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_swiper_05.jpeg",
    am_emptiness_phonecase_swiper_06:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_swiper_06.png",
    am_emptiness_phonecase_swiper_07:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_swiper_07.png",
    am_emptiness_phonecase_swiper_08:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_swiper_08.png",
    am_emptiness_phonecase_swiper_09:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_swiper_09.jpeg",
    am_emptiness_phonecase_swiper_10:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_swiper_10.png",
    am_emptiness_phonecase_swiper_11:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_swiper_11.jpeg",
    am_emptiness_phonecase_swiper_12:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_swiper_12.jpeg",
    am_emptiness_phonecase_swiper_13:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_swiper_13.png",
    am_emptiness_phonecase_swiper_14:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_swiper_14.png",
    am_emptiness_phonecase_swiper_15:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_swiper_15.png",
    am_emptiness_phonecase_swiper_16:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_swiper_16.png",
    am_emptiness_phonecase_swiper_17:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_swiper_17.png",
    am_emptiness_phonecase_swiper_18:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_swiper_18.png",
    am_emptiness_phonecase_swiper_19:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_swiper_19.png",
    am_emptiness_phonecase_swiper_20:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_swiper_20.png",
    am_emptiness_phonecase_swiper_21:
      ossDir + "am_emptiness_phonecase/am_emptiness_phonecase_swiper_21.png",

    cybercharge_35_kv: ossDir + "cybercharge_35/cybercharge_35_kv.jpg",
    cybercharge_35_kv_en: ossDir + "cybercharge_35/cybercharge_35_kv_en.jpg",
    cybercharge_35_01_cn: ossDir + "cybercharge_35/cybercharge_35_01_cn.jpg",
    cybercharge_35_01_en: ossDir + "cybercharge_35/cybercharge_35_01_en.jpg",
    cybercharge_35_02: ossDir + "cybercharge_35/cybercharge_35_02.jpg",
    cybercharge_35_02_en: ossDir + "cybercharge_35/cybercharge_35_02_en.jpg",
    cybercharge_35_03: ossDir + "cybercharge_35/cybercharge_35_03.jpg",
    cybercharge_35_04: ossDir + "cybercharge_35/cybercharge_35_04.jpg",
    cybercharge_35_05: ossDir + "cybercharge_35/cybercharge_35_05.jpg",
    cybercharge_35_06: ossDir + "cybercharge_35/cybercharge_35_06.jpg",
    cybercharge_35_07: ossDir + "cybercharge_35/cybercharge_35_07.jpg",
    cybercharge_35_08_cn: ossDir + "cybercharge_35/cybercharge_35_08_cn.jpg",
    cybercharge_35_08_en: ossDir + "cybercharge_35/cybercharge_35_08_en.jpg",
    cybercharge_35_09: ossDir + "cybercharge_35/cybercharge_35_09.jpg",
    cybercharge_35_10: ossDir + "cybercharge_35/cybercharge_35_10.jpg",
    cybercharge_35_11_cn: ossDir + "cybercharge_35/cybercharge_35_11_cn.jpg",
    cybercharge_35_11_en: ossDir + "cybercharge_35/cybercharge_35_11_en.jpg",
    cybercharge_35_12_cn: ossDir + "cybercharge_35/cybercharge_35_12_cn.jpg",
    cybercharge_35_12_en: ossDir + "cybercharge_35/cybercharge_35_12_en.jpg",
    cybercharge_35_13_cn: ossDir + "cybercharge_35/cybercharge_35_13_cn.jpg",
    cybercharge_35_14_cn: ossDir + "cybercharge_35/cybercharge_35_14_cn.jpg",
    cybercharge_35_14_en: ossDir + "cybercharge_35/cybercharge_35_14_en.jpg",

    cybercharge_35_2_01: ossDir + "cybercharge_35/cybercharge_35_2_01.jpg",
    cybercharge_35_2_01_en:
      ossDir + "cybercharge_35/cybercharge_35_2_01_en.jpg",
    cybercharge_35_2_02: ossDir + "cybercharge_35/cybercharge_35_2_02.jpg",
    cybercharge_35_2_03: ossDir + "cybercharge_35/cybercharge_35_2_03.jpg",
    cybercharge_35_2_04: ossDir + "cybercharge_35/cybercharge_35_2_04.jpg",
    cybercharge_35_2_05: ossDir + "cybercharge_35/cybercharge_35_2_05.jpg",
    cybercharge_35_2_06: ossDir + "cybercharge_35/cybercharge_35_2_06.jpg",
    cybercharge_35_2_07: ossDir + "cybercharge_35/cybercharge_35_2_07.jpg",
    cybercharge_35_2_08: ossDir + "cybercharge_35/cybercharge_35_2_08.jpg",
    cybercharge_35_2_09: ossDir + "cybercharge_35/cybercharge_35_2_09.jpg",
    cybercharge_35_2_10: ossDir + "cybercharge_35/cybercharge_35_2_10.jpg",
    cybercharge_35_2_11: ossDir + "cybercharge_35/cybercharge_35_2_11.jpg",
    cybercharge_35_2_12: ossDir + "cybercharge_35/cybercharge_35_2_12.jpg",
    cybercharge_35_2_13: ossDir + "cybercharge_35/cybercharge_35_2_13.jpg",
    cybercharge_35_2_14: ossDir + "cybercharge_35/cybercharge_35_2_14.jpg",
    cybercharge_35_2_14_en:
      ossDir + "cybercharge_35/cybercharge_35_2_14_en.jpg",
    cybercharge_35_2_15: ossDir + "cybercharge_35/cybercharge_35_2_15.jpg",
    cybercharge_35_2_16: ossDir + "cybercharge_35/cybercharge_35_2_16.jpg",
    cybercharge_35_2_17: ossDir + "cybercharge_35/cybercharge_35_2_17.jpg",
    cybercharge_35_2_17_en:
      ossDir + "cybercharge_35/cybercharge_35_2_17_en.jpg",

    am_keyboard_assessories_01:
      ossDir + "am_keyboard_assessories/am_keyboard_assessories_01.jpg",
    am_keyboard_assessories_02:
      ossDir + "am_keyboard_assessories/am_keyboard_assessories_02.jpg",
    am_keyboard_assessories_03:
      ossDir + "am_keyboard_assessories/am_keyboard_assessories_03.jpg",
    am_keyboard_assessories_04:
      ossDir + "am_keyboard_assessories/am_keyboard_assessories_04.jpg",
    am_keyboard_assessories_05:
      ossDir + "am_keyboard_assessories/am_keyboard_assessories_05.jpg",

    cyberboard_r4_kv_cn: ossDir + "cyberboard_r4/cyberboard_r4_kv_cn.jpg",
    cyberboard_r4_kv_en: ossDir + "cyberboard_r4/cyberboard_r4_kv_en.jpg",
    cyberboard_r4_kv_mobile_cn:
      ossDir + "cyberboard_r4/cyberboard_r4_kv_mobile_cn.jpg",
    cyberboard_r4_kv_mobile_en:
      ossDir + "cyberboard_r4/cyberboard_r4_kv_mobile_en.jpg",
    cyberboard_r4_01: ossDir + "cyberboard_r4/cyberboard_r4_01.jpeg",
    cyberboard_r4_02: ossDir + "cyberboard_r4/cyberboard_r4_02.jpg",
    cyberboard_r4_03: ossDir + "cyberboard_r4/cyberboard_r4_03.jpg",
    cyberboard_r4_04: ossDir + "cyberboard_r4/cyberboard_r4_04.jpg",
    cyberboard_r4_05: ossDir + "cyberboard_r4/cyberboard_r4_05.jpg",
    cyberboard_r4_06: ossDir + "cyberboard_r4/cyberboard_r4_06.jpg",
    cyberboard_r4_07: ossDir + "cyberboard_r4/cyberboard_r4_07.jpg",
    cyberboard_r4_08: ossDir + "cyberboard_r4/cyberboard_r4_08.jpg",
    cyberboard_r4_09: ossDir + "cyberboard_r4/cyberboard_r4_09.jpg",
    cyberboard_r4_10: ossDir + "cyberboard_r4/cyberboard_r4_10.jpg",
    cyberboard_r4_11: ossDir + "cyberboard_r4/cyberboard_r4_11.jpg",
    cyberboard_r4_12: ossDir + "cyberboard_r4/cyberboard_r4_12.jpg",
    cyberboard_r4_13: ossDir + "cyberboard_r4/cyberboard_r4_13.jpg",
    cyberboard_r4_14: ossDir + "cyberboard_r4/cyberboard_r4_14.jpg",
    cyberboard_r4_15: ossDir + "cyberboard_r4/cyberboard_r4_15.jpg",
    cyberboard_r4_16: ossDir + "cyberboard_r4/cyberboard_r4_16.jpg",
    cyberboard_r4_16_01: ossDir + "cyberboard_r4/cyberboard_r4_16_01.jpg",
    cyberboard_r4_16_02: ossDir + "cyberboard_r4/cyberboard_r4_16_02.jpg",
    cyberboard_r4_16_03: ossDir + "cyberboard_r4/cyberboard_r4_16_03.jpg",
    cyberboard_r4_17: ossDir + "cyberboard_r4/cyberboard_r4_17.jpg",
    cyberboard_r4_17_01: ossDir + "cyberboard_r4/cyberboard_r4_17_01.jpg",
    cyberboard_r4_17_02: ossDir + "cyberboard_r4/cyberboard_r4_17_02.jpg",
    cyberboard_r4_17_03: ossDir + "cyberboard_r4/cyberboard_r4_17_03.jpg",
    cyberboard_r4_18: ossDir + "cyberboard_r4/cyberboard_r4_18.jpg",
    cyberboard_r4_18_01: ossDir + "cyberboard_r4/cyberboard_r4_18_01.jpg",
    cyberboard_r4_18_02: ossDir + "cyberboard_r4/cyberboard_r4_18_02.jpg",
    cyberboard_r4_18_03: ossDir + "cyberboard_r4/cyberboard_r4_18_03.jpg",
    cyberboard_r4_18_04: ossDir + "cyberboard_r4/cyberboard_r4_18_04.jpg",
    cyberboard_r4_19: ossDir + "cyberboard_r4/cyberboard_r4_19.jpg",
    cyberboard_r4_19_01: ossDir + "cyberboard_r4/cyberboard_r4_19_01.jpg",
    cyberboard_r4_19_02: ossDir + "cyberboard_r4/cyberboard_r4_19_02.jpg",
    cyberboard_r4_19_03: ossDir + "cyberboard_r4/cyberboard_r4_19_03.jpg",
    cyberboard_r4_20: ossDir + "cyberboard_r4/cyberboard_r4_20.gif",
    cyberboard_r4_21: ossDir + "cyberboard_r4/cyberboard_r4_21.gif",
    cyberboard_r4_22: ossDir + "cyberboard_r4/cyberboard_r4_22.gif",
    cyberboard_r4_23: ossDir + "cyberboard_r4/cyberboard_r4_23.gif",
    cyberboard_r4_24: ossDir + "cyberboard_r4/cyberboard_r4_24.png",
    cyberboard_r4_24_en: ossDir + "cyberboard_r4/cyberboard_r4_24_en.png",
    cyberboard_r4_25: ossDir + "cyberboard_r4/cyberboard_r4_25.jpg",
    cyberboard_r4_26: ossDir + "cyberboard_r4/cyberboard_r4_26.jpg",
    cyberboard_r4_27: ossDir + "cyberboard_r4/cyberboard_r4_27.jpg",
    cyberboard_r4_28: ossDir + "cyberboard_r4/cyberboard_r4_28.jpg",
    cyberboard_r4_29: ossDir + "cyberboard_r4/cyberboard_r4_29.jpg",
    cyberboard_r4_30: ossDir + "cyberboard_r4/cyberboard_r4_30.jpg",
    cyberboard_r4_31: ossDir + "cyberboard_r4/cyberboard_r4_31.jpg",
    cyberboard_r4_32: ossDir + "cyberboard_r4/cyberboard_r4_32.jpg",
    cyberboard_r4_33: ossDir + "cyberboard_r4/cyberboard_r4_33.jpg",
    cyberboard_r4_34: ossDir + "cyberboard_r4/cyberboard_r4_34.jpg",
    cyberboard_r4_35: ossDir + "cyberboard_r4/cyberboard_r4_35.jpg",
    cyberboard_r4_36: ossDir + "cyberboard_r4/cyberboard_r4_36.jpg",
    cyberboard_r4_37: ossDir + "cyberboard_r4/cyberboard_r4_37.png",
    cyberboard_r4_38: ossDir + "cyberboard_r4/cyberboard_r4_38.png",
    cyberboard_r4_39: ossDir + "cyberboard_r4/cyberboard_r4_39.png",
    cyberboard_r4_40: ossDir + "cyberboard_r4/cyberboard_r4_40.png",
    cyberboard_r4_41: ossDir + "cyberboard_r4/cyberboard_r4_41.mp4",
    cyberboard_r4_42: ossDir + "cyberboard_r4/cyberboard_r4_42.jpg",
    cyberboard_r4_43: ossDir + "cyberboard_r4/cyberboard_r4_43.jpg",
    cyberboard_r4_43_en: ossDir + "cyberboard_r4/cyberboard_r4_43_en.jpg",
    cyberblade_swiper_01_01:
      ossDir + "cyberboard_r4/cyberblade_swiper_01_01.jpg",
    cyberblade_swiper_01_02:
      ossDir + "cyberboard_r4/cyberblade_swiper_01_02.jpg",
    cyberblade_swiper_01_03:
      ossDir + "cyberboard_r4/cyberblade_swiper_01_03.jpg",
    cyberblade_swiper_01_04:
      ossDir + "cyberboard_r4/cyberblade_swiper_01_04.jpg",
    cyberblade_swiper_01_05:
      ossDir + "cyberboard_r4/cyberblade_swiper_01_05.jpg",
    cyberblade_swiper_01_06:
      ossDir + "cyberboard_r4/cyberblade_swiper_01_06.jpg",
    cyberblade_swiper_01_07:
      ossDir + "cyberboard_r4/cyberblade_swiper_01_07.jpg",
    cyberblade_swiper_01_08:
      ossDir + "cyberboard_r4/cyberblade_swiper_01_08.jpg",
    cyberblade_swiper_01_09:
      ossDir + "cyberboard_r4/cyberblade_swiper_01_09.jpg",
    cyberblade_swiper_01_10:
      ossDir + "cyberboard_r4/cyberblade_swiper_01_10.jpg",
    cyberblade_swiper_01_11:
      ossDir + "cyberboard_r4/cyberblade_swiper_01_11.jpg",
    cyberblade_swiper_01_12:
      ossDir + "cyberboard_r4/cyberblade_swiper_01_12.jpg",
    cyberblade_swiper_01_13:
      ossDir + "cyberboard_r4/cyberblade_swiper_01_13.jpg",
    cyberblade_swiper_01_14:
      ossDir + "cyberboard_r4/cyberblade_swiper_01_14.jpg",
    cyberblade_swiper_02_01:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_01.jpg",
    cyberblade_swiper_02_02:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_02.jpg",
    cyberblade_swiper_02_03:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_03.jpg",
    cyberblade_swiper_02_04:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_04.jpg",
    cyberblade_swiper_02_05:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_05.jpg",
    cyberblade_swiper_02_06:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_06.jpg",
    cyberblade_swiper_02_07:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_07.jpg",
    cyberblade_swiper_02_08:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_08.jpg",
    cyberblade_swiper_02_09:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_09.jpg",
    cyberblade_swiper_02_10:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_10.jpg",
    cyberblade_swiper_02_11:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_11.jpg",
    cyberblade_swiper_02_12:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_12.jpg",
    cyberblade_swiper_02_13:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_13.jpg",
    cyberblade_swiper_02_14:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_14.jpg",
    cyberblade_swiper_02_15:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_15.jpg",
    cyberblade_swiper_02_16:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_16.jpg",
    cyberblade_swiper_02_17:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_17.jpg",
    cyberblade_swiper_02_18:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_18.jpg",
    cyberblade_swiper_02_19:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_19.jpg",
    cyberblade_swiper_02_20:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_20.jpg",
    cyberblade_swiper_02_21:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_21.jpg",
    cyberblade_swiper_02_22:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_22.jpg",
    cyberblade_swiper_02_23:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_23.jpg",
    cyberblade_swiper_02_24:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_24.jpg",
    cyberblade_swiper_02_25:
      ossDir + "cyberboard_r4/cyberblade_swiper_02_25.jpg",

    icy_silver_switch_pro_kv_cn:
      ossDir + "icy_silver_switch_pro/icy_silver_switch_pro_kv_cn.png",
    icy_silver_switch_pro_kv_en:
      ossDir + "icy_silver_switch_pro/icy_silver_switch_pro_kv_en.png",
    icy_silver_switch_pro_kv_mobile_cn:
      ossDir + "icy_silver_switch_pro/icy_silver_switch_pro_kv_mobile_cn.png",
    icy_silver_switch_pro_kv_mobile_en:
      ossDir + "icy_silver_switch_pro/icy_silver_switch_pro_kv_mobile_en.png",
    icy_silver_switch_pro_01:
      ossDir + "icy_silver_switch_pro/icy_silver_switch_pro_01.jpg",
    icy_silver_switch_pro_02:
      ossDir + "icy_silver_switch_pro/icy_silver_switch_pro_02.jpeg",
    icy_silver_switch_pro_03:
      ossDir + "icy_silver_switch_pro/icy_silver_switch_pro_03.jpeg",
    icy_silver_switch_pro_04:
      ossDir + "icy_silver_switch_pro/icy_silver_switch_pro_04.jpg",
    icy_silver_switch_pro_05:
      ossDir + "icy_silver_switch_pro/icy_silver_switch_pro_05.png",
    icy_silver_switch_pro_05_en:
      ossDir + "icy_silver_switch_pro/icy_silver_switch_pro_05_en.png",
    icy_silver_switch_pro_06:
      ossDir + "icy_silver_switch_pro/icy_silver_switch_pro_06.mp4",
    icy_silver_switch_pro_07:
      ossDir + "icy_silver_switch_pro/icy_silver_switch_pro_07.png",
    icy_silver_switch_pro_07_en:
      ossDir + "icy_silver_switch_pro/icy_silver_switch_pro_07_en.jpg",

    cosmo_killer_r2_kv_cn: ossDir + "cosmo_killer_r2/cosmo_killer_r2_kv_cn.jpg",
    cosmo_killer_r2_kv_en: ossDir + "cosmo_killer_r2/cosmo_killer_r2_kv_en.jpg",
    cosmo_killer_r2_kv_mobile_cn:
      ossDir + "cosmo_killer_r2/cosmo_killer_r2_kv_cn.jpg",
    cosmo_killer_r2_kv_mobile_en:
      ossDir + "cosmo_killer_r2/cosmo_killer_r2_kv_en.jpg",
    cosmo_killer_r2_01: ossDir + "cosmo_killer_r2/cosmo_killer_r2_01.jpg",
    cosmo_killer_r2_02: ossDir + "cosmo_killer_r2/cosmo_killer_r2_02.jpg",
    cosmo_killer_r2_03: ossDir + "cosmo_killer_r2/cosmo_killer_r2_03.jpg",
    cosmo_killer_r2_04: ossDir + "cosmo_killer_r2/cosmo_killer_r2_04.jpg",
    cosmo_killer_r2_05: ossDir + "cosmo_killer_r2/cosmo_killer_r2_05.jpg",
    cosmo_killer_r2_06: ossDir + "cosmo_killer_r2/cosmo_killer_r2_06.jpg",
    cosmo_killer_r2_07: ossDir + "cosmo_killer_r2/cosmo_killer_r2_07.jpg",
    cosmo_killer_r2_08: ossDir + "cosmo_killer_r2/cosmo_killer_r2_08.jpg",
    cosmo_killer_r2_09: ossDir + "cosmo_killer_r2/cosmo_killer_r2_09.jpg",
    cosmo_killer_r2_10: ossDir + "cosmo_killer_r2/cosmo_killer_r2_10.jpg",
    cosmo_killer_r2_11: ossDir + "cosmo_killer_r2/cosmo_killer_r2_11.jpg",
    cosmo_killer_r2_12: ossDir + "cosmo_killer_r2/cosmo_killer_r2_12.jpg",
    cosmo_killer_r2_13: ossDir + "cosmo_killer_r2/cosmo_killer_r2_13.jpg",
    cosmo_killer_r2_14: ossDir + "cosmo_killer_r2/cosmo_killer_r2_14.jpg",
    cosmo_killer_r2_15_cn: ossDir + "cosmo_killer_r2/cosmo_killer_r2_15_cn.jpg",
    cosmo_killer_r2_15_en: ossDir + "cosmo_killer_r2/cosmo_killer_r2_15_en.jpg",

    am_afa_r2_kv_cn: ossDir + "am_afa_r2/am_afa_r2_kv_cn.jpg",
    am_afa_r2_kv_en: ossDir + "am_afa_r2/am_afa_r2_kv_en.png",
    am_afa_r2_kv_mobile_cn: ossDir + "am_afa_r2/am_afa_r2_kv_mobile_cn.png",
    am_afa_r2_kv_mobile_en: ossDir + "am_afa_r2/am_afa_r2_kv_mobile_en.png",
    am_afa_r2_01: ossDir + "am_afa_r2/am_afa_r2_01.png",
    am_afa_r2_02: ossDir + "am_afa_r2/am_afa_r2_02.jpeg",
    am_afa_r2_03: ossDir + "am_afa_r2/am_afa_r2_03.png",
    am_afa_r2_04: ossDir + "am_afa_r2/am_afa_r2_04.png",
    am_afa_r2_05: ossDir + "am_afa_r2/am_afa_r2_05.png",
    am_afa_r2_06: ossDir + "am_afa_r2/am_afa_r2_06.jpeg",
    am_afa_r2_06_01: ossDir + "am_afa_r2/am_afa_r2_06_01.jpg",
    am_afa_r2_06_02: ossDir + "am_afa_r2/am_afa_r2_06_02.jpg",
    am_afa_r2_06_03: ossDir + "am_afa_r2/am_afa_r2_06_03.jpg",
    am_afa_r2_06_04: ossDir + "am_afa_r2/am_afa_r2_06_04.jpg",
    am_afa_r2_06_05: ossDir + "am_afa_r2/am_afa_r2_06_05.jpg",
    am_afa_r2_06_06: ossDir + "am_afa_r2/am_afa_r2_06_06.jpg",
    am_afa_r2_07: ossDir + "am_afa_r2/am_afa_r2_07.png",
    am_afa_r2_08: ossDir + "am_afa_r2/am_afa_r2_08.png",
    am_afa_r2_09: ossDir + "am_afa_r2/am_afa_r2_09.jpg",
    am_afa_r2_09_01: ossDir + "am_afa_r2/am_afa_r2_09_01.jpg",
    am_afa_r2_09_02: ossDir + "am_afa_r2/am_afa_r2_09_02.jpg",
    am_afa_r2_09_03: ossDir + "am_afa_r2/am_afa_r2_09_03.jpg",
    am_afa_r2_09_04: ossDir + "am_afa_r2/am_afa_r2_09_04.jpg",
    am_afa_r2_10: ossDir + "am_afa_r2/am_afa_r2_10.png",
    am_afa_r2_11: ossDir + "am_afa_r2/am_afa_r2_11.png",
    am_afa_r2_12: ossDir + "am_afa_r2/am_afa_r2_12.jpeg",
    am_afa_r2_12_01: ossDir + "am_afa_r2/am_afa_r2_12_01.jpeg",
    am_afa_r2_12_02: ossDir + "am_afa_r2/am_afa_r2_12_02.jpeg",
    am_afa_r2_12_03: ossDir + "am_afa_r2/am_afa_r2_12_03.jpeg",
    am_afa_r2_12_04: ossDir + "am_afa_r2/am_afa_r2_12_04.jpeg",
    am_afa_r2_13: ossDir + "am_afa_r2/am_afa_r2_13.png",
    am_afa_r2_14: ossDir + "am_afa_r2/am_afa_r2_14.png",
    am_afa_r2_15: ossDir + "am_afa_r2/am_afa_r2_15.jpeg",
    am_afa_r2_15_01: ossDir + "am_afa_r2/am_afa_r2_15_01.jpeg",
    am_afa_r2_15_02: ossDir + "am_afa_r2/am_afa_r2_15_02.jpeg",
    am_afa_r2_15_03: ossDir + "am_afa_r2/am_afa_r2_15_03.jpeg",
    am_afa_r2_15_04: ossDir + "am_afa_r2/am_afa_r2_15_04.jpeg",
    am_afa_r2_16: ossDir + "am_afa_r2/am_afa_r2_16.png",
    am_afa_r2_17: ossDir + "am_afa_r2/am_afa_r2_17.png",
    am_afa_r2_18: ossDir + "am_afa_r2/am_afa_r2_18.jpg",
    am_afa_r2_18_01: ossDir + "am_afa_r2/am_afa_r2_18_01.jpeg",
    am_afa_r2_18_02: ossDir + "am_afa_r2/am_afa_r2_18_02.jpg",
    am_afa_r2_18_03: ossDir + "am_afa_r2/am_afa_r2_18_03.jpg",
    am_afa_r2_18_04: ossDir + "am_afa_r2/am_afa_r2_18_04.jpg",
    am_afa_r2_18_05: ossDir + "am_afa_r2/am_afa_r2_18_05.jpg",
    am_afa_r2_19: ossDir + "am_afa_r2/am_afa_r2_19.jpg",
    am_afa_r2_20: ossDir + "am_afa_r2/am_afa_r2_20.jpg",
    am_afa_r2_21: ossDir + "am_afa_r2/am_afa_r2_21.jpg",
    am_afa_r2_21_01: ossDir + "am_afa_r2/am_afa_r2_21_01.jpeg",
    am_afa_r2_21_02: ossDir + "am_afa_r2/am_afa_r2_21_02.jpg",
    am_afa_r2_21_03: ossDir + "am_afa_r2/am_afa_r2_21_03.jpeg",
    am_afa_r2_21_04: ossDir + "am_afa_r2/am_afa_r2_21_04.jpeg",
    am_afa_r2_21_05: ossDir + "am_afa_r2/am_afa_r2_21_05.jpeg",
    am_afa_r2_21_06: ossDir + "am_afa_r2/am_afa_r2_21_06.jpeg",
    am_afa_r2_22: ossDir + "am_afa_r2/am_afa_r2_22.png",
    am_afa_r2_23: ossDir + "am_afa_r2/am_afa_r2_23.jpeg",
    am_afa_r2_24: ossDir + "am_afa_r2/am_afa_r2_24.png",
    am_afa_r2_25: ossDir + "am_afa_r2/am_afa_r2_25.png",
    am_afa_r2_26: ossDir + "am_afa_r2/am_afa_r2_26.png",
    am_afa_r2_27: ossDir + "am_afa_r2/am_afa_r2_27.png",
    am_afa_r2_28_cn: ossDir + "am_afa_r2/am_afa_r2_28_cn.mp4",
    am_afa_r2_28_en: ossDir + "am_afa_r2/am_afa_r2_28_en.mp4",
    am_afa_r2_29: ossDir + "am_afa_r2/am_afa_r2_29.jpeg",
    am_afa_r2_30: ossDir + "am_afa_r2/am_afa_r2_30.png",
    am_afa_r2_31: ossDir + "am_afa_r2/am_afa_r2_31.jpeg",
    am_afa_r2_32_cn: ossDir + "am_afa_r2/am_afa_r2_32_cn.mp4",
    am_afa_r2_32_en: ossDir + "am_afa_r2/am_afa_r2_32_en.mp4",
    am_afa_r2_33: ossDir + "am_afa_r2/am_afa_r2_33.jpeg",
    am_afa_r2_34: ossDir + "am_afa_r2/am_afa_r2_34.jpeg",
    am_afa_r2_35_cn: ossDir + "am_afa_r2/am_afa_r2_35_cn.mp4",
    am_afa_r2_35_en: ossDir + "am_afa_r2/am_afa_r2_35_en.mp4",
    am_afa_r2_36: ossDir + "am_afa_r2/am_afa_r2_36.jpeg",
    am_afa_r2_37: ossDir + "am_afa_r2/am_afa_r2_37.jpeg",
    am_afa_r2_38: ossDir + "am_afa_r2/am_afa_r2_38.jpeg",
    am_afa_r2_39: ossDir + "am_afa_r2/am_afa_r2_39.jpeg",
    am_afa_r2_40: ossDir + "am_afa_r2/am_afa_r2_40.jpeg",
    am_afa_r2_41: ossDir + "am_afa_r2/am_afa_r2_41.jpeg",
    am_afa_r2_42: ossDir + "am_afa_r2/am_afa_r2_42.jpeg",
    am_afa_r2_43: ossDir + "am_afa_r2/am_afa_r2_43.jpeg",
    am_afa_r2_44: ossDir + "am_afa_r2/am_afa_r2_44.jpeg",
    am_afa_r2_45_cn: ossDir + "am_afa_r2/am_afa_r2_45_cn.png",
    am_afa_r2_45_en: ossDir + "am_afa_r2/am_afa_r2_45_en.png",
    am_afa_r2_46: ossDir + "am_afa_r2/am_afa_r2_46.jpg",
    am_afa_r2_47: ossDir + "am_afa_r2/am_afa_r2_47.jpeg",
    am_afa_r2_48: ossDir + "am_afa_r2/am_afa_r2_48.jpeg",
    am_afa_r2_49: ossDir + "am_afa_r2/am_afa_r2_49.png",
    am_afa_r2_50: ossDir + "am_afa_r2/am_afa_r2_50.png",
    am_afa_r2_51: ossDir + "am_afa_r2/am_afa_r2_51.png",
    am_afa_r2_52: ossDir + "am_afa_r2/am_afa_r2_52.png",
    am_afa_r2_53_cn: ossDir + "am_afa_r2/am_afa_r2_53_cn.png",
    am_afa_r2_53_en: ossDir + "am_afa_r2/am_afa_r2_53_en.png",
    am_afa_r2_54_cn: ossDir + "am_afa_r2/am_afa_r2_54_cn.png",
    am_afa_r2_54_en: ossDir + "am_afa_r2/am_afa_r2_54_en.png",
    am_afa_r2_55: ossDir + "am_afa_r2/am_afa_r2_55.jpeg",
    am_afa_r2_arrow: ossDir + "am_afa_r2/am_afa_r2_arrow.png",

    cyberboard_r2_5_disassembly_guide:
      ossDir + "cyberboard_r2_5/cyberboard_r2_5_disassembly_guide.mp4",

    am_compact_touch_split_kv_cn:
      ossDir + "am_compact_touch_split/am_compact_touch_split_kv_cn.png",
    am_compact_touch_split_kv_en:
      ossDir + "am_compact_touch_split/am_compact_touch_split_kv_en.png",

    carpio_am_editon_kv_cn:
      ossDir + "carpio_am_editon/carpio_am_editon_kv_cn.png",
    carpio_am_editon_kv_en:
      ossDir + "carpio_am_editon/carpio_am_editon_kv_en.png",
    carpio_am_editon_01_cn:
      ossDir + "carpio_am_editon/carpio_am_editon_01_cn.jpg",
    carpio_am_editon_01_en:
      ossDir + "carpio_am_editon/carpio_am_editon_01_en.jpg",
    carpio_am_editon_01_mobile_cn:
      ossDir + "carpio_am_editon/carpio_am_editon_01_mobile_cn.jpg",
    carpio_am_editon_01_mobile_en:
      ossDir + "carpio_am_editon/carpio_am_editon_01_mobile_en.jpg",
    carpio_am_editon_02: ossDir + "carpio_am_editon/carpio_am_editon_02.jpg",
    carpio_am_editon_03: ossDir + "carpio_am_editon/carpio_am_editon_03.jpg",
    carpio_am_editon_04: ossDir + "carpio_am_editon/carpio_am_editon_04.jpg",
    carpio_am_editon_05: ossDir + "carpio_am_editon/carpio_am_editon_05.jpg",
    carpio_am_editon_06: ossDir + "carpio_am_editon/carpio_am_editon_06.mp4",
    carpio_am_editon_07_cn:
      ossDir + "carpio_am_editon/carpio_am_editon_07_cn.png",
    carpio_am_editon_07_en:
      ossDir + "carpio_am_editon/carpio_am_editon_07_en.png",
    carpio_am_editon_08_cn:
      ossDir + "carpio_am_editon/carpio_am_editon_08_cn.png",
    carpio_am_editon_08_en:
      ossDir + "carpio_am_editon/carpio_am_editon_08_en.png",
    carpio_am_editon_09_cn:
      ossDir + "carpio_am_editon/carpio_am_editon_09_cn.png",
    carpio_am_editon_09_en:
      ossDir + "carpio_am_editon/carpio_am_editon_09_en.png",
    carpio_am_editon_10: ossDir + "carpio_am_editon/carpio_am_editon_10.png",
    carpio_am_editon_11: ossDir + "carpio_am_editon/carpio_am_editon_11.png",
    carpio_g2_0_am_editon_user_guide:
      ossDir + "carpio_am_editon/carpio_g2_0_am_editon_user_guide.mp4",

    am_65_less_01_cn: ossDir + "am_65_less/am_65_less_01_cn.jpg",
    am_65_less_01_en: ossDir + "am_65_less/am_65_less_01_en.jpg",
    am_65_less_01_mobile_cn: ossDir + "am_65_less/am_65_less_01_mobile_cn.png",
    am_65_less_01_mobile_en: ossDir + "am_65_less/am_65_less_01_mobile_en.png",
    am_65_less_02: ossDir + "am_65_less/am_65_less_02.jpg",
    am_65_less_03: ossDir + "am_65_less/am_65_less_03.mp4",
    am_65_less_04: ossDir + "am_65_less/am_65_less_04.jpg",
    am_65_less_05: ossDir + "am_65_less/am_65_less_05.jpg",
    am_65_less_06: ossDir + "am_65_less/am_65_less_06.jpg",
    am_65_less_07: ossDir + "am_65_less/am_65_less_07.gif",
    am_65_less_08: ossDir + "am_65_less/am_65_less_08.png",
    am_65_less_09: ossDir + "am_65_less/am_65_less_09.png",
    am_65_less_10: ossDir + "am_65_less/am_65_less_10.png",
    am_65_less_11: ossDir + "am_65_less/am_65_less_11.jpg",
    am_65_less_12: ossDir + "am_65_less/am_65_less_12.jpg",
    am_65_less_13_cn: ossDir + "am_65_less/am_65_less_13_cn.mp4",
    am_65_less_13_en: ossDir + "am_65_less/am_65_less_13_en.mp4",
    am_65_less_14: ossDir + "am_65_less/am_65_less_14.jpg",
    am_65_less_15: ossDir + "am_65_less/am_65_less_15.jpg",
    am_65_less_16: ossDir + "am_65_less/am_65_less_16.jpg",
    am_65_less_17: ossDir + "am_65_less/am_65_less_17.jpg",
    am_65_less_18: ossDir + "am_65_less/am_65_less_18.jpg",
    am_65_less_19: ossDir + "am_65_less/am_65_less_19.jpg",
    am_65_less_20: ossDir + "am_65_less/am_65_less_20.jpeg",
    am_65_less_21_cn: ossDir + "am_65_less/am_65_less_21_cn.jpeg",
    am_65_less_21_en: ossDir + "am_65_less/am_65_less_21_en.jpg",
    am_65_less_22_cn: ossDir + "am_65_less/am_65_less_22_cn.jpeg",
    am_65_less_22_en: ossDir + "am_65_less/am_65_less_22_en.jpg",
    am_65_less_23_cn: ossDir + "am_65_less/am_65_less_23_cn.png",
    am_65_less_23_en: ossDir + "am_65_less/am_65_less_23_en.png",
    am_65_less_24: ossDir + "am_65_less/am_65_less_24.png",
    am_65_less_25: ossDir + "am_65_less/am_65_less_25.png",
    am_65_less_26_cn: ossDir + "am_65_less/am_65_less_26_cn.png",
    am_65_less_26_en: ossDir + "am_65_less/am_65_less_26_en.png",
    am_65_less_26_ja: ossDir + "am_65_less/am_65_less_26_ja.png",
    am_65_less_swiper_01_01: ossDir + "am_65_less/am_65_less_swiper_01_01.JPG",
    am_65_less_swiper_01_02: ossDir + "am_65_less/am_65_less_swiper_01_02.JPG",
    am_65_less_swiper_01_03: ossDir + "am_65_less/am_65_less_swiper_01_03.JPG",
    am_65_less_swiper_01_04: ossDir + "am_65_less/am_65_less_swiper_01_04.JPG",
    am_65_less_swiper_02_01: ossDir + "am_65_less/am_65_less_swiper_02_01.jpg",
    am_65_less_swiper_02_02: ossDir + "am_65_less/am_65_less_swiper_02_02.jpg",
    am_65_less_swiper_02_03: ossDir + "am_65_less/am_65_less_swiper_02_03.jpg",
    am_65_less_swiper_03_01: ossDir + "am_65_less/am_65_less_swiper_03_01.jpg",
    am_65_less_swiper_03_02: ossDir + "am_65_less/am_65_less_swiper_03_02.jpg",
    am_65_less_swiper_03_03: ossDir + "am_65_less/am_65_less_swiper_03_03.jpg",
    am_65_less_swiper_04_01: ossDir + "am_65_less/am_65_less_swiper_04_01.jpg",
    am_65_less_swiper_04_02: ossDir + "am_65_less/am_65_less_swiper_04_02.jpg",
    am_65_less_swiper_04_03: ossDir + "am_65_less/am_65_less_swiper_04_03.jpg",
    am_65_less_swiper_04_04: ossDir + "am_65_less/am_65_less_swiper_04_04.jpg",
    am_65_less_swiper_04_05: ossDir + "am_65_less/am_65_less_swiper_04_05.jpg",
    am_65_less_swiper_05_01: ossDir + "am_65_less/am_65_less_swiper_05_01.jpg",
    am_65_less_swiper_05_02: ossDir + "am_65_less/am_65_less_swiper_05_02.jpg",
    am_65_less_swiper_05_03: ossDir + "am_65_less/am_65_less_swiper_05_03.jpg",
    am_65_less_swiper_06_01: ossDir + "am_65_less/am_65_less_swiper_06_01.png",
    am_65_less_swiper_06_02: ossDir + "am_65_less/am_65_less_swiper_06_02.png",
    am_65_less_swiper_06_03: ossDir + "am_65_less/am_65_less_swiper_06_03.png",
    am_65_less_swiper_07_01: ossDir + "am_65_less/am_65_less_swiper_07_01.jpg",
    am_65_less_swiper_07_02: ossDir + "am_65_less/am_65_less_swiper_07_02.jpg",
    am_65_less_swiper_07_03: ossDir + "am_65_less/am_65_less_swiper_07_03.jpg",
    am_65_less_swiper_07_04: ossDir + "am_65_less/am_65_less_swiper_07_04.jpg",
    am_65_less_swiper_07_05: ossDir + "am_65_less/am_65_less_swiper_07_05.jpg",
    am_65_less_swiper_08_01: ossDir + "am_65_less/am_65_less_swiper_08_01.JPG",
    am_65_less_swiper_08_02: ossDir + "am_65_less/am_65_less_swiper_08_02.JPG",
    am_65_less_swiper_08_03: ossDir + "am_65_less/am_65_less_swiper_08_03.JPG",
    am_65_less_swiper_08_04: ossDir + "am_65_less/am_65_less_swiper_08_04.JPG",
    am_65_less_swiper_08_05: ossDir + "am_65_less/am_65_less_swiper_08_05.JPG",
    am_65_less_swiper_08_06: ossDir + "am_65_less/am_65_less_swiper_08_06.JPG",
    am_65_less_swiper_09_01: ossDir + "am_65_less/am_65_less_swiper_09_01.JPG",
    am_65_less_swiper_09_02: ossDir + "am_65_less/am_65_less_swiper_09_02.JPG",
    am_65_less_swiper_09_03: ossDir + "am_65_less/am_65_less_swiper_09_03.JPG",
    am_65_less_swiper_09_04: ossDir + "am_65_less/am_65_less_swiper_09_04.JPG",
    am_65_less_swiper_10_01: ossDir + "am_65_less/am_65_less_swiper_10_01.jpg",
    am_65_less_swiper_10_02: ossDir + "am_65_less/am_65_less_swiper_10_02.jpg",
    am_65_less_swiper_10_03: ossDir + "am_65_less/am_65_less_swiper_10_03.jpg",
    am_65_less_swiper_10_04: ossDir + "am_65_less/am_65_less_swiper_10_04.jpg",
    am_65_less_swiper_11_01: ossDir + "am_65_less/am_65_less_swiper_11_01.JPG",
    am_65_less_swiper_11_02: ossDir + "am_65_less/am_65_less_swiper_11_02.JPG",
    am_65_less_swiper_11_03: ossDir + "am_65_less/am_65_less_swiper_11_03.JPG",
    am_65_less_swiper_11_04: ossDir + "am_65_less/am_65_less_swiper_11_04.JPG",
    am_65_less_swiper_11_05: ossDir + "am_65_less/am_65_less_swiper_11_05.JPG",
    am_65_less_swiper_11_06: ossDir + "am_65_less/am_65_less_swiper_11_06.JPG",
    am_65_less_swiper_12_01: ossDir + "am_65_less/am_65_less_swiper_12_01.jpg",
    am_65_less_swiper_12_02: ossDir + "am_65_less/am_65_less_swiper_12_02.jpg",
    am_65_less_custom_kv: ossDir + "am_65_less/am_65_less_custom_kv.png",
    am_65_less_custom_top: ossDir + "am_65_less/am_65_less_custom_top.png",

    am_65_less_fading_01_cn:
      ossDir + "am_65_less_fading/am_65_less_fading_01_cn.jpg",
    am_65_less_fading_01_mobile_cn:
      ossDir + "am_65_less_fading/am_65_less_fading_01_mobile_cn.png",
    am_65_less_fading_02:
      ossDir + "am_65_less_fading/am_65_less_fading_02.jpeg",
    am_65_less_fading_03:
      ossDir + "am_65_less_fading/am_65_less_fading_03.jpeg",
    am_65_less_fading_04: ossDir + "am_65_less_fading/am_65_less_fading_04.png",
    am_65_less_fading_05: ossDir + "am_65_less_fading/am_65_less_fading_05.png",
    am_65_less_fading_06: ossDir + "am_65_less_fading/am_65_less_fading_06.png",
    am_65_less_fading_07: ossDir + "am_65_less_fading/am_65_less_fading_07.png",
    am_65_less_fading_08: ossDir + "am_65_less_fading/am_65_less_fading_08.png",
    am_65_less_fading_09_cn:
      ossDir + "am_65_less_fading/am_65_less_fading_09_cn.mp4",
    am_65_less_fading_10: ossDir + "am_65_less_fading/am_65_less_fading_10.mp4",
    am_65_less_fading_11: ossDir + "am_65_less_fading/am_65_less_fading_11.png",

    cosmo_killer_kv_cn: ossDir + "cosmo_killer/cosmo_killer_kv_cn.jpg",
    cosmo_killer_kv_en: ossDir + "cosmo_killer/cosmo_killer_kv_en.jpg",
    cosmo_killer_kv_mobile_cn:
      ossDir + "cosmo_killer/cosmo_killer_kv_mobile_cn.jpg",
    cosmo_killer_kv_mobile_en:
      ossDir + "cosmo_killer/cosmo_killer_kv_mobile_en.jpg",
    cosmo_killer_01: ossDir + "cosmo_killer/cosmo_killer_01.png",
    cosmo_killer_02: ossDir + "cosmo_killer/cosmo_killer_02.png",
    cosmo_killer_03: ossDir + "cosmo_killer/cosmo_killer_03.jpg",
    cosmo_killer_04: ossDir + "cosmo_killer/cosmo_killer_04.jpg",
    cosmo_killer_05: ossDir + "cosmo_killer/cosmo_killer_05.jpg",
    cosmo_killer_06: ossDir + "cosmo_killer/cosmo_killer_06.jpg",
    cosmo_killer_07: ossDir + "cosmo_killer/cosmo_killer_07.jpg",
    cosmo_killer_08: ossDir + "cosmo_killer/cosmo_killer_08.jpg",
    cosmo_killer_09: ossDir + "cosmo_killer/cosmo_killer_09.jpg",
    cosmo_killer_10_cn: ossDir + "cosmo_killer/cosmo_killer_10_cn.png",
    cosmo_killer_10_en: ossDir + "cosmo_killer/cosmo_killer_10_en.png",
    cosmo_killer_11: ossDir + "cosmo_killer/cosmo_killer_11.png",
    cosmo_killer_12: ossDir + "cosmo_killer/cosmo_killer_12.png",
    cosmo_killer_13: ossDir + "cosmo_killer/cosmo_killer_13.png",
    cosmo_killer_14: ossDir + "cosmo_killer/cosmo_killer_14.png",
    cosmo_killer_15: ossDir + "cosmo_killer/cosmo_killer_15.png",
    cosmo_killer_16: ossDir + "cosmo_killer/cosmo_killer_16.png",

    am_master_kv_cn: ossDir + "am_master/am_master_kv_cn.jpg",
    am_master_kv_en: ossDir + "am_master/am_master_kv_en.jpg",
    am_master_kv_ja: ossDir + "am_master/am_master_kv_ja.jpg",
    am_master_kv_mobile_cn: ossDir + "am_master/am_master_kv_mobile_cn.jpg",
    am_master_kv_mobile_en: ossDir + "am_master/am_master_kv_mobile_en.jpg",
    am_master_kv_mobile_ja: ossDir + "am_master/am_master_kv_mobile_ja.jpg",
    am_master_01: ossDir + "am_master/am_master_01.png",
    am_master_02_cn: ossDir + "am_master/am_master_02_cn.png",
    am_master_02_en: ossDir + "am_master/am_master_02_en.jpg",
    am_master_03_cn: ossDir + "am_master/am_master_03_cn.png",
    am_master_03_en: ossDir + "am_master/am_master_03_en.jpg",
    am_master_04_cn: ossDir + "am_master/am_master_04_cn.mp4",

    hover_kv_cn: ossDir + "hover/hover_kv_cn.jpg",
    hover_kv_en: ossDir + "hover/hover_kv_en.jpg",
    hover_01: ossDir + "hover/hover_01.jpg",
    hover_02: ossDir + "hover/hover_02.jpg",
    hover_03: ossDir + "hover/hover_03.jpg",
    hover_04: ossDir + "hover/hover_04.jpg",

    am_afa_graffiti_kv: ossDir + "am_afa_graffiti/am_afa_graffiti_kv.jpg",
    am_afa_graffiti_kv_en: ossDir + "am_afa_graffiti/am_afa_graffiti_kv_en.jpg",
    am_afa_graffiti_kv_mobile:
      ossDir + "am_afa_graffiti/am_afa_graffiti_kv_mobile.jpg",
    am_afa_graffiti_kv_mobile_en:
      ossDir + "am_afa_graffiti/am_afa_graffiti_kv_mobile_en.jpg",
    am_afa_graffiti_download:
      ossDir + "am_afa_graffiti/am_afa_graffiti_download.png",
    am_afa_graffiti_download_en:
      ossDir + "am_afa_graffiti/am_afa_graffiti_download_en.png",
    am_afa_graffiti_upload:
      ossDir + "am_afa_graffiti/am_afa_graffiti_upload.png",
    am_afa_graffiti_upload_en:
      ossDir + "am_afa_graffiti/am_afa_graffiti_upload_en.png",
    am_afa_graffiti_zip:
      ossDir +
      "am_afa_graffiti/%E6%80%92%E5%96%B5%20AM%20AFA%20%E5%85%A8%E7%90%83%E6%B6%82%E9%B8%A6%E5%A4%A7%E8%B5%9B%E5%9F%BA%E7%A1%80%E5%BA%95%E7%A8%BF.zip",
    am_afa_graffiti_zip_en:
      ossDir +
      "am_afa_graffiti/AM%20AFA%20DIY%20Graffiti%20Competition%20Draft.zip",

    am_afa_kv_cn: ossDir + "am_afa/am_afa_kv_cn.png",
    am_afa_kv_mobile_cn: ossDir + "am_afa/am_afa_kv_mobile_cn.png",
    am_afa_kv_en: ossDir + "am_afa/am_afa_kv_en.png",
    am_afa_kv_mobile_en: ossDir + "am_afa/am_afa_kv_mobile_en.png",
    am_afa_kv_ja: ossDir + "am_afa/am_afa_kv_ja.jpg",
    am_afa_kv_mobile_ja: ossDir + "am_afa/am_afa_kv_mobile_ja.png",
    am_afa_01: ossDir + "am_afa/am_afa_01.jpg",
    am_afa_02: ossDir + "am_afa/am_afa_02.jpg",
    am_afa_03: ossDir + "am_afa/am_afa_03.jpg",
    am_afa_04: ossDir + "am_afa/am_afa_04.jpg",
    am_afa_05: ossDir + "am_afa/am_afa_05.jpg",
    am_afa_06_cn: ossDir + "am_afa/am_afa_06_cn.png",
    am_afa_06_en: ossDir + "am_afa/am_afa_06_en.png",
    am_afa_07_cn: ossDir + "am_afa/am_afa_07_cn.mp4",
    am_afa_07_en: ossDir + "am_afa/am_afa_07_en.mp4",
    am_afa_08_cn: ossDir + "am_afa/am_afa_08_cn.png",
    am_afa_08_en: ossDir + "am_afa/am_afa_08_en.png",
    am_afa_09: ossDir + "am_afa/am_afa_09.mp4",
    am_afa_10: ossDir + "am_afa/am_afa_10.png",
    am_afa_11: ossDir + "am_afa/am_afa_11.jpg",
    am_afa_12: ossDir + "am_afa/am_afa_12.jpg",
    am_afa_13: ossDir + "am_afa/am_afa_13.jpg",
    am_afa_14: ossDir + "am_afa/am_afa_14.jpg",
    am_afa_15_cn: ossDir + "am_afa/am_afa_15_cn.jpg",
    am_afa_15_en: ossDir + "am_afa/am_afa_15_en.jpg",
    am_afa_16: ossDir + "am_afa/am_afa_16.png",
    am_afa_17: ossDir + "am_afa/am_afa_17.png",
    am_afa_18: ossDir + "am_afa/am_afa_18.png",
    am_afa_19: ossDir + "am_afa/am_afa_19.png",
    am_afa_20: ossDir + "am_afa/am_afa_20.jpg",
    am_afa_21_cn: ossDir + "am_afa/am_afa_21_cn.png",
    am_afa_21_en: ossDir + "am_afa/am_afa_21_en.jpg",
    am_afa_21_ja: ossDir + "am_afa/am_afa_21_ja.png",
    am_afa_22: ossDir + "am_afa/am_afa_22.png",
    am_afa_swiper_01_01: ossDir + "am_afa/am_afa_swiper_01_01.jpg",
    am_afa_swiper_01_02: ossDir + "am_afa/am_afa_swiper_01_02.jpg",
    am_afa_swiper_01_03: ossDir + "am_afa/am_afa_swiper_01_03.jpg",
    am_afa_swiper_01_04: ossDir + "am_afa/am_afa_swiper_01_04.jpg",
    am_afa_swiper_02_01: ossDir + "am_afa/am_afa_swiper_02_01.jpg",
    am_afa_swiper_02_02: ossDir + "am_afa/am_afa_swiper_02_02.jpg",
    am_afa_swiper_02_03: ossDir + "am_afa/am_afa_swiper_02_03.jpg",
    am_afa_swiper_02_04: ossDir + "am_afa/am_afa_swiper_02_04.jpg",
    am_afa_swiper_02_05: ossDir + "am_afa/am_afa_swiper_02_05.jpg",
    am_afa_swiper_03_01: ossDir + "am_afa/am_afa_swiper_03_01.jpg",
    am_afa_swiper_03_02: ossDir + "am_afa/am_afa_swiper_03_02.jpg",
    am_afa_swiper_04_01: ossDir + "am_afa/am_afa_swiper_04_01.jpg",
    am_afa_swiper_04_02: ossDir + "am_afa/am_afa_swiper_04_02.jpg",
    am_afa_swiper_04_03: ossDir + "am_afa/am_afa_swiper_04_03.jpg",
    am_afa_swiper_05_01: ossDir + "am_afa/am_afa_swiper_05_01.jpg",
    am_afa_swiper_05_02: ossDir + "am_afa/am_afa_swiper_05_02.jpg",
    am_afa_swiper_06_01: ossDir + "am_afa/am_afa_swiper_06_01.jpg",
    am_afa_swiper_06_02: ossDir + "am_afa/am_afa_swiper_06_02.jpg",
    am_afa_swiper_06_03: ossDir + "am_afa/am_afa_swiper_06_03.jpg",
    am_afa_swiper_06_04: ossDir + "am_afa/am_afa_swiper_06_04.jpg",

    cyberboard_typing_kit_kv:
      ossDir + "cyberboard_typing_kit/cyberboard_typing_kit_kv.jpg",
    cyberboard_typing_kit_01:
      ossDir + "cyberboard_typing_kit/cyberboard_typing_kit_01.jpg",
    cyberboard_typing_kit_02:
      ossDir + "cyberboard_typing_kit/cyberboard_typing_kit_02.jpg",
    cyberboard_typing_kit_03:
      ossDir + "cyberboard_typing_kit/cyberboard_typing_kit_03.jpg",
    cyberboard_typing_kit_04:
      ossDir + "cyberboard_typing_kit/cyberboard_typing_kit_04.jpg",
    cyberboard_typing_kit_05:
      ossDir + "cyberboard_typing_kit/cyberboard_typing_kit_05.jpg",
    cyberboard_typing_kit_06:
      ossDir + "cyberboard_typing_kit/cyberboard_typing_kit_06.jpg",
    cyberboard_typing_kit_07:
      ossDir + "cyberboard_typing_kit/cyberboard_typing_kit_07.jpg",
    cyberboard_typing_kit_08:
      ossDir + "cyberboard_typing_kit/cyberboard_typing_kit_08.jpg",
    cyberboard_typing_kit_09:
      ossDir + "cyberboard_typing_kit/cyberboard_typing_kit_09.jpg",
    cyberboard_typing_kit_10:
      ossDir + "cyberboard_typing_kit/cyberboard_typing_kit_10.jpg",
    cyberboard_typing_kit_11:
      ossDir + "cyberboard_typing_kit/cyberboard_typing_kit_11.mp4",

    cyberblade_base_01_en: ossDir + "cyberblade/cyberblade_base_01_en.jpg",
    cyberblade_base_02_en: ossDir + "cyberblade/cyberblade_base_02_en.jpg",
    cyberblade_base_03_en: ossDir + "cyberblade/cyberblade_base_03_en.jpg",
    cyberblade_base_04_en: ossDir + "cyberblade/cyberblade_base_04_en.jpeg",
    cyberblade_base_05_en: ossDir + "cyberblade/cyberblade_base_05_en.png",
    cyberblade_kv_cn: ossDir + "cyberblade/cyberblade_kv_cn.jpg",
    cyberblade_kv_en: ossDir + "cyberblade/cyberblade_kv_en.mp4",
    cyberblade_01_cn: ossDir + "cyberblade/cyberblade_01_cn.png",
    cyberblade_01_cn_mobile: ossDir + "cyberblade/cyberblade_01_cn_mobile.png",
    cyberblade_01_en: ossDir + "cyberblade/cyberblade_01_en.png",
    cyberblade_01_en_mobile: ossDir + "cyberblade/cyberblade_01_en_mobile.png",
    cyberblade_02: ossDir + "cyberblade/cyberblade_02.png",
    cyberblade_03: ossDir + "cyberblade/cyberblade_03.mp4",
    cyberblade_04_01: ossDir + "cyberblade/cyberblade_04_01.png",
    cyberblade_04_02: ossDir + "cyberblade/cyberblade_04_02.png",
    cyberblade_04_03: ossDir + "cyberblade/cyberblade_04_03.png",
    cyberblade_05: ossDir + "cyberblade/cyberblade_05.png",
    cyberblade_06: ossDir + "cyberblade/cyberblade_06.png",
    cyberblade_06_mobile: ossDir + "cyberblade/cyberblade_06_mobile.png",
    cyberblade_07: ossDir + "cyberblade/cyberblade_07.png",
    cyberblade_07_mobile: ossDir + "cyberblade/cyberblade_07_mobile.png",
    cyberblade_08: ossDir + "cyberblade/cyberblade_08.png",
    cyberblade_09: ossDir + "cyberblade/cyberblade_09.png",
    cyberblade_10: ossDir + "cyberblade/cyberblade_10.png",
    cyberblade_11: ossDir + "cyberblade/cyberblade_11.png",
    cyberblade_12: ossDir + "cyberblade/cyberblade_12.png",
    cyberblade_13: ossDir + "cyberblade/cyberblade_13.mp4",
    cyberblade_14: ossDir + "cyberblade/cyberblade_14.mp4",
    cyberblade_15: ossDir + "cyberblade/cyberblade_15.png",
    cyberblade_16: ossDir + "cyberblade/cyberblade_16.png",
    cyberblade_17: ossDir + "cyberblade/cyberblade_17.jpg",
    cyberblade_17_mobile: ossDir + "cyberblade/cyberblade_17_mobile.png",
    cyberblade_18: ossDir + "cyberblade/cyberblade_18.jpg",
    cyberblade_19: ossDir + "cyberblade/cyberblade_19.png",
    cyberblade_20_cn: ossDir + "cyberblade/cyberblade_20_cn.png",
    cyberblade_20_en: ossDir + "cyberblade/cyberblade_20_en.png",
    cyberblade_20_ja: ossDir + "cyberblade/cyberblade_20_ja.png",
    cyberblade_21_cn: ossDir + "cyberblade/cyberblade_21_cn.png",
    cyberblade_21_en: ossDir + "cyberblade/cyberblade_21_en.png",
    cyberblade_21_ja: ossDir + "cyberblade/cyberblade_21_ja.png",
    cyberblade_22_cn: ossDir + "cyberblade/cyberblade_22_cn.mp4",
    cyberblade_23_cn: ossDir + "cyberblade/cyberblade_23_cn.jpg",
    cyberblade_arrow_left: ossDir + "cyberblade/cyberblade_arrow_left.png",
    cyberblade_arrow_right: ossDir + "cyberblade/cyberblade_arrow_right.png",
    cyberblade_plate_01: ossDir + "cyberblade/cyberblade_plate_01.jpg",
    cyberblade_plate_02: ossDir + "cyberblade/cyberblade_plate_02.jpg",
    cyberblade_plate_03: ossDir + "cyberblade/cyberblade_plate_03.jpg",
    cyberblade_plate_03_en: ossDir + "cyberblade/cyberblade_plate_03_en.jpg",
    cyberblade_plate_04: ossDir + "cyberblade/cyberblade_plate_04.jpg",
    cyberblade_plate_04_en: ossDir + "cyberblade/cyberblade_plate_04_en.jpg",
    cyberblade_plate_05: ossDir + "cyberblade/cyberblade_plate_05.png",
    cyberblade_icon_01: ossDir + "cyberblade/cyberblade_icon_01.png",
    cyberblade_icon_02: ossDir + "cyberblade/cyberblade_icon_02.png",
    cyberblade_icon_03: ossDir + "cyberblade/cyberblade_icon_03.png",
    cyberblade_icon_04: ossDir + "cyberblade/cyberblade_icon_04.png",
    cyberblade_icon_05: ossDir + "cyberblade/cyberblade_icon_05.png",
    cyberblade_icon_06: ossDir + "cyberblade/cyberblade_icon_06.png",
    cyberblade_modal_bg: ossDir + "cyberblade/cyberblade_modal_bg.png",
    cyberblade_modal_bg_mobile:
      ossDir + "cyberblade/cyberblade_modal_bg_mobile.png",
    cyberblade_swiper_01: ossDir + "cyberblade/cyberblade_swiper_01.jpg",
    cyberblade_swiper_02: ossDir + "cyberblade/cyberblade_swiper_02.jpeg",
    cyberblade_swiper_03: ossDir + "cyberblade/cyberblade_swiper_03.jpg",
    cyberblade_swiper_04: ossDir + "cyberblade/cyberblade_swiper_04.png",
    cyberblade_swiper_05: ossDir + "cyberblade/cyberblade_swiper_05.jpg",
    cyberblade_swiper_06: ossDir + "cyberblade/cyberblade_swiper_06.jpg",
    cyberblade_swiper_07: ossDir + "cyberblade/cyberblade_swiper_07.jpg",
    cyberblade_swiper_08: ossDir + "cyberblade/cyberblade_swiper_08.jpg",
    cyberblade_swiper_09: ossDir + "cyberblade/cyberblade_swiper_09.jpg",
    cyberblade_swiper_10: ossDir + "cyberblade/cyberblade_swiper_10.jpg",
    cyberblade_swiper_11: ossDir + "cyberblade/cyberblade_swiper_11.jpg",
    cyberblade_swiper_12: ossDir + "cyberblade/cyberblade_swiper_12.jpeg",
    cyberblade_swiper_13: ossDir + "cyberblade/cyberblade_swiper_13.jpg",
    cyberblade_swiper_14: ossDir + "cyberblade/cyberblade_swiper_14.png",
    cyberblade_swiper_15: ossDir + "cyberblade/cyberblade_swiper_15.jpg",
    cyberblade_swiper_16: ossDir + "cyberblade/cyberblade_swiper_16.png",
    cyberblade_swiper_17: ossDir + "cyberblade/cyberblade_swiper_17.png",
    cyberblade_swiper_18: ossDir + "cyberblade/cyberblade_swiper_18.jpeg",
    cyberblade_swiper_19: ossDir + "cyberblade/cyberblade_swiper_19.png",
    cyberblade_swiper_20: ossDir + "cyberblade/cyberblade_swiper_20.png",
    cyberblade_swiper_21: ossDir + "cyberblade/cyberblade_swiper_21.jpg",
    cyberblade_swiper_22: ossDir + "cyberblade/cyberblade_swiper_22.jpeg",
    cyberblade_swiper_23: ossDir + "cyberblade/cyberblade_swiper_23.jpeg",

    cyberblade_en_01: ossDir + "cyberblade/cyberblade_en_01.jpg",
    cyberblade_en_01_mobile: ossDir + "cyberblade/cyberblade_en_01_mobile.png",
    cyberblade_en_02: ossDir + "cyberblade/cyberblade_en_02.png",
    cyberblade_en_03: ossDir + "cyberblade/cyberblade_en_03.png",
    cyberblade_en_04: ossDir + "cyberblade/cyberblade_en_04.png",
    cyberblade_en_05: ossDir + "cyberblade/cyberblade_en_05.jpg",
    cyberblade_en_06_01: ossDir + "cyberblade/cyberblade_en_06_01.png",
    cyberblade_en_06_02: ossDir + "cyberblade/cyberblade_en_06_02.png",
    cyberblade_en_06_03: ossDir + "cyberblade/cyberblade_en_06_03.png",
    cyberblade_en_06_04: ossDir + "cyberblade/cyberblade_en_06_04.png",
    cyberblade_en_06_05: ossDir + "cyberblade/cyberblade_en_06_05.png",
    cyberblade_en_06_06: ossDir + "cyberblade/cyberblade_en_06_06.png",
    cyberblade_en_07: ossDir + "cyberblade/cyberblade_en_07.jpg",
    cyberblade_en_08: ossDir + "cyberblade/cyberblade_en_08.png",
    cyberblade_en_09: ossDir + "cyberblade/cyberblade_en_09.jpg",
    cyberblade_en_10: ossDir + "cyberblade/cyberblade_en_10.jpg",
    cyberblade_en_11: ossDir + "cyberblade/cyberblade_en_11.png",
    cyberblade_en_12: ossDir + "cyberblade/cyberblade_en_12.png",
    cyberblade_en_13: ossDir + "cyberblade/cyberblade_en_13.png",
    cyberblade_en_14: ossDir + "cyberblade/cyberblade_en_14.gif",
    cyberblade_en_15: ossDir + "cyberblade/cyberblade_en_15.jpg",
    cyberblade_en_15_mobile: ossDir + "cyberblade/cyberblade_en_15_mobile.png",
    cyberblade_en_16: ossDir + "cyberblade/cyberblade_en_16.jpg",
    cyberblade_en_17: ossDir + "cyberblade/cyberblade_en_17.png",
    cyberblade_en_18: ossDir + "cyberblade/cyberblade_en_18.jpg",
    cyberblade_en_19: ossDir + "cyberblade/cyberblade_en_19.png",
    cyberblade_en_20: ossDir + "cyberblade/cyberblade_en_20.jpg",
    cyberblade_en_21: ossDir + "cyberblade/cyberblade_en_21.jpg",
    cyberblade_en_22: ossDir + "cyberblade/cyberblade_en_22.png",
    cyberblade_en_23: ossDir + "cyberblade/cyberblade_en_23.jpg",
    cyberblade_en_24: ossDir + "cyberblade/cyberblade_en_24.jpeg",
    cyberblade_en_25: ossDir + "cyberblade/cyberblade_en_25.png",
    cyberblade_en_26: ossDir + "cyberblade/cyberblade_en_26.png",
    cyberblade_en_27: ossDir + "cyberblade/cyberblade_en_27.jpg",
    cyberblade_en_28: ossDir + "cyberblade/cyberblade_en_28.png",
    cyberblade_en_29: ossDir + "cyberblade/cyberblade_en_29.png",
    cyberblade_en_30: ossDir + "cyberblade/cyberblade_en_30.jpg",
    cyberblade_en_31: ossDir + "cyberblade/cyberblade_en_31.jpg",
    cyberblade_en_32: ossDir + "cyberblade/cyberblade_en_32.jpg",
    cyberblade_en_title_01: ossDir + "cyberblade/cyberblade_en_title_01.jpg",
    cyberblade_en_title_02: ossDir + "cyberblade/cyberblade_en_title_02.jpg",
    cyberblade_en_title_03: ossDir + "cyberblade/cyberblade_en_title_03.jpg",
    cyberblade_en_title_04: ossDir + "cyberblade/cyberblade_en_title_04.jpg",
    cyberblade_en_title_04_01:
      ossDir + "cyberblade/cyberblade_en_title_04_01.jpg",
    cyberblade_en_title_04_02:
      ossDir + "cyberblade/cyberblade_en_title_04_02.jpg",
    cyberblade_en_title_04_03:
      ossDir + "cyberblade/cyberblade_en_title_04_03.jpg",
    cyberblade_en_title_04_04:
      ossDir + "cyberblade/cyberblade_en_title_04_04.jpg",
    cyberblade_en_title_05: ossDir + "cyberblade/cyberblade_en_title_05.jpg",
    cyberblade_en_title_06: ossDir + "cyberblade/cyberblade_en_title_06.jpg",
    cyberblade_en_title_06_01:
      ossDir + "cyberblade/cyberblade_en_title_06_01.jpg",
    cyberblade_en_title_06_02:
      ossDir + "cyberblade/cyberblade_en_title_06_02.jpg",
    cyberblade_en_title_07: ossDir + "cyberblade/cyberblade_en_title_07.jpg",
    cyberblade_en_title_08: ossDir + "cyberblade/cyberblade_en_title_08.jpg",
    cyberblade_en_title_09: ossDir + "cyberblade/cyberblade_en_title_09.jpg",
    cyberblade_en_title_10: ossDir + "cyberblade/cyberblade_en_title_10.jpg",
    cyberblade_en_title_11: ossDir + "cyberblade/cyberblade_en_title_11.jpg",
    cyberblade_en_title_12: ossDir + "cyberblade/cyberblade_en_title_12.jpg",
    cyberblade_en_title_13: ossDir + "cyberblade/cyberblade_en_title_13.jpg",
    cyberblade_en_title_14: ossDir + "cyberblade/cyberblade_en_title_14.png",
    cyberblade_en_title_15: ossDir + "cyberblade/cyberblade_en_title_15.png",

    diy_competition_contribute_cn:
      ossDir + "diy_competition/diy_competition_contribute_cn.png",
    diy_competition_contribute_en:
      ossDir + "diy_competition/diy_competition_contribute_en.png",
    diy_competition_line: ossDir + "diy_competition/diy_competition_line.png",
    diy_competition_led_strip:
      ossDir + "diy_competition/diy_competition_led_strip.png",
    diy_competition_example_01:
      ossDir + "diy_competition/diy_competition_example_01.gif",
    diy_competition_example_02:
      ossDir + "diy_competition/diy_competition_example_02.gif",
    diy_competition_square:
      ossDir + "diy_competition/diy_competition_square.png",
    diy_competition_box: ossDir + "diy_competition/diy_competition_box.png",
    diy_competition_form_cn:
      ossDir + "diy_competition/diy_competition_form_cn.png",
    diy_competition_form_en:
      ossDir + "diy_competition/diy_competition_form_en.png",
    diy_competition_arrow: ossDir + "diy_competition/diy_competition_arrow.png",
    diy_competition_timeline:
      ossDir + "diy_competition/diy_competition_timeline.png",

    diy_tutorial_cyberboard_cn: ossDir + "diy/tutorial_cyberboard_cn.mp4",
    diy_tutorial_cyberboard_en: ossDir + "diy/tutorial_cyberboard_en.mp4",

    cyberboard_terminal_bg:
      ossDir + "cyberboard_terminal/cyberboard_terminal_bg.png",
    cyberboard_terminal_kv:
      ossDir + "cyberboard_terminal/cyberboard_terminal_kv.mp4",
    cyberboard_terminal_01_cn:
      ossDir + "cyberboard_terminal/cyberboard_terminal_01_cn.png",
    cyberboard_terminal_01_en:
      ossDir + "cyberboard_terminal/cyberboard_terminal_01_en.png",
    cyberboard_terminal_01_cn_mobile:
      ossDir + "cyberboard_terminal/cyberboard_terminal_01_cn_mobile.png",
    cyberboard_terminal_01_en_mobile:
      ossDir + "cyberboard_terminal/cyberboard_terminal_01_en_mobile.png",
    cyberboard_terminal_02_cn:
      ossDir + "cyberboard_terminal/cyberboard_terminal_02_cn.png",
    cyberboard_terminal_02_en:
      ossDir + "cyberboard_terminal/cyberboard_terminal_02_en.png",
    cyberboard_terminal_02_cn_mobile:
      ossDir + "cyberboard_terminal/cyberboard_terminal_02_cn_mobile.png",
    cyberboard_terminal_02_en_mobile:
      ossDir + "cyberboard_terminal/cyberboard_terminal_02_en_mobile.png",
    cyberboard_terminal_03:
      ossDir + "cyberboard_terminal/cyberboard_terminal_03.png",
    cyberboard_terminal_04:
      ossDir + "cyberboard_terminal/cyberboard_terminal_04.mp4",
    cyberboard_terminal_05:
      ossDir + "cyberboard_terminal/cyberboard_terminal_05.png",
    cyberboard_terminal_06:
      ossDir + "cyberboard_terminal/cyberboard_terminal_06.mp4",
    cyberboard_terminal_07:
      ossDir + "cyberboard_terminal/cyberboard_terminal_07.png",
    cyberboard_terminal_08:
      ossDir + "cyberboard_terminal/cyberboard_terminal_08.png",
    cyberboard_terminal_09:
      ossDir + "cyberboard_terminal/cyberboard_terminal_09.png",
    cyberboard_terminal_10:
      ossDir + "cyberboard_terminal/cyberboard_terminal_10.png",
    cyberboard_terminal_swiper_01:
      ossDir + "cyberboard_terminal/cyberboard_terminal_swiper_01.jpg",
    cyberboard_terminal_swiper_02:
      ossDir + "cyberboard_terminal/cyberboard_terminal_swiper_02.jpg",
    cyberboard_terminal_swiper_03:
      ossDir + "cyberboard_terminal/cyberboard_terminal_swiper_03.jpg",
    cyberboard_terminal_swiper_04:
      ossDir + "cyberboard_terminal/cyberboard_terminal_swiper_04.jpg",
    cyberboard_terminal_swiper_05:
      ossDir + "cyberboard_terminal/cyberboard_terminal_swiper_05.jpg",
    cyberboard_terminal_swiper_06:
      ossDir + "cyberboard_terminal/cyberboard_terminal_swiper_06.jpg",
    cyberboard_terminal_swiper_07:
      ossDir + "cyberboard_terminal/cyberboard_terminal_swiper_07.jpg",
    cyberboard_terminal_swiper_08:
      ossDir + "cyberboard_terminal/cyberboard_terminal_swiper_08.jpg",
    cyberboard_terminal_swiper_09:
      ossDir + "cyberboard_terminal/cyberboard_terminal_swiper_09.jpg",

    cybermat_r2_01: ossDir + "cybermat_r2/cybermat_r2_01.jpg",
    cybermat_r2_01_mobile: ossDir + "cybermat_r2/cybermat_r2_01_mobile.jpg",
    cybermat_r2_02: ossDir + "cybermat_r2/cybermat_r2_02.png",
    cybermat_r2_03: ossDir + "cybermat_r2/cybermat_r2_03.jpg",
    cybermat_r2_04: ossDir + "cybermat_r2/cybermat_r2_04.jpg",
    cybermat_r2_05: ossDir + "cybermat_r2/cybermat_r2_05.jpg",
    cybermat_r2_icon_01: ossDir + "cybermat_r2/cybermat_r2_icon_01.png",
    cybermat_r2_icon_02: ossDir + "cybermat_r2/cybermat_r2_icon_02.png",
    cybermat_r2_icon_03: ossDir + "cybermat_r2/cybermat_r2_icon_03.png",
    cybermat_r2_icon_04: ossDir + "cybermat_r2/cybermat_r2_icon_04.png",
    cybermat_r2_icon_05: ossDir + "cybermat_r2/cybermat_r2_icon_05.png",
    cybermat_r2_icon_06: ossDir + "cybermat_r2/cybermat_r2_icon_06.png",

    cyberboard_r3_01: ossDir + "cyberboard_r3/cyberboard_r3_01.jpg",
    cyberboard_r3_01_mobile:
      ossDir + "cyberboard_r3/cyberboard_r3_01_mobile.jpg",
    cyberboard_r3_02: ossDir + "cyberboard_r3/cyberboard_r3_02.jpg",
    cyberboard_r3_03: ossDir + "cyberboard_r3/cyberboard_r3_03.jpg",
    cyberboard_r3_04: ossDir + "cyberboard_r3/cyberboard_r3_04.jpg",
    cyberboard_r3_05: ossDir + "cyberboard_r3/cyberboard_r3_05.jpg",
    cyberboard_r3_06: ossDir + "cyberboard_r3/cyberboard_r3_06.jpg",
    cyberboard_r3_07: ossDir + "cyberboard_r3/cyberboard_r3_07.jpg",
    cyberboard_r3_08: ossDir + "cyberboard_r3/cyberboard_r3_08.jpg",
    cyberboard_r3_09: ossDir + "cyberboard_r3/cyberboard_r3_09.png",
    cyberboard_r3_10: ossDir + "cyberboard_r3/cyberboard_r3_10.jpg",

    cyberboard_gb_kv: ossDir + "cyberboard_gb/cyberboard_gb_kv.mp4",
    cyberboard_gb_01_cn: ossDir + "cyberboard_gb/cyberboard_gb_01_cn.jpg",
    cyberboard_gb_01_en: ossDir + "cyberboard_gb/cyberboard_gb_01_en.jpg",
    cyberboard_gb_01_mobile_cn:
      ossDir + "cyberboard_gb/cyberboard_gb_01_mobile_cn.jpg",
    cyberboard_gb_01_mobile_en:
      ossDir + "cyberboard_gb/cyberboard_gb_01_mobile_en.jpg",
    cyberboard_gb_02: ossDir + "cyberboard_gb/cyberboard_gb_02.mp4",
    cyberboard_gb_03: ossDir + "cyberboard_gb/cyberboard_gb_03.jpg",
    cyberboard_gb_04: ossDir + "cyberboard_gb/cyberboard_gb_04.jpg",
    cyberboard_gb_05: ossDir + "cyberboard_gb/cyberboard_gb_05.mp4",
    cyberboard_gb_06: ossDir + "cyberboard_gb/cyberboard_gb_06.png",
    cyberboard_gb_line: ossDir + "cyberboard_gb/cyberboard_gb_line.png",
    cyberboard_gb_icon_01: ossDir + "cyberboard_gb/cyberboard_gb_icon_01.png",
    cyberboard_gb_icon_02: ossDir + "cyberboard_gb/cyberboard_gb_icon_02.png",
    cyberboard_gb_icon_03: ossDir + "cyberboard_gb/cyberboard_gb_icon_03.png",

    cyberboard_r2_line: ossDir + "cyberboard_r2/cyberboard_r2_line.png",
    cyberboard_r2_01: ossDir + "cyberboard_r2/cyberboard_r2_01.jpg",
    cyberboard_r2_01_mobile:
      ossDir + "cyberboard_r2/cyberboard_r2_01_mobile.jpg",
    cyberboard_r2_02: ossDir + "cyberboard_r2/cyberboard_r2_02.jpg",
    cyberboard_r2_02_mobile:
      ossDir + "cyberboard_r2/cyberboard_r2_02_mobile.png",
    cyberboard_r2_03: ossDir + "cyberboard_r2/cyberboard_r2_03.png",
    cyberboard_r2_04: ossDir + "cyberboard_r2/cyberboard_r2_04.jpg",
    cyberboard_r2_04_02: ossDir + "cyberboard_r2/cyberboard_r2_05.png",
    cyberboard_r2_05: ossDir + "cyberboard_r2/cyberboard_r2_05.mp4",
    cyberboard_r2_06: ossDir + "cyberboard_r2/cyberboard_r2_06.png",
    cyberboard_r2_07: ossDir + "cyberboard_r2/cyberboard_r2_07.mp4",
    cyberboard_r2_08: ossDir + "cyberboard_r2/cyberboard_r2_08.gif",
    cyberboard_r2_09: ossDir + "cyberboard_r2/cyberboard_r2_09.mp4",
    cyberboard_r2_10: ossDir + "cyberboard_r2/cyberboard_r2_10.png",
    cyberboard_r2_11: ossDir + "cyberboard_r2/cyberboard_r2_11.png",
    cyberboard_r2_12: ossDir + "cyberboard_r2/cyberboard_r2_12.png",
    cyberboard_r2_13: ossDir + "cyberboard_r2/cyberboard_r2_13.jpg",
    cyberboard_r2_13_mobile:
      ossDir + "cyberboard_r2/cyberboard_r2_13_mobile.png",
    cyberboard_r2_swiper_01_01:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_01_01.jpg",
    cyberboard_r2_swiper_01_02:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_01_02.jpg",
    cyberboard_r2_swiper_02_01:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_02_01.jpg",
    cyberboard_r2_swiper_02_02:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_02_02.jpg",
    cyberboard_r2_swiper_03_01:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_03_01.jpg",
    cyberboard_r2_swiper_03_02:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_03_02.jpg",
    cyberboard_r2_swiper_03_03:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_03_03.jpg",
    cyberboard_r2_swiper_03_04:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_03_04.jpg",
    cyberboard_r2_swiper_04_01:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_04_01.jpg",
    cyberboard_r2_swiper_04_02:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_04_02.jpg",
    cyberboard_r2_swiper_04_03:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_04_03.jpg",
    cyberboard_r2_swiper_05_01:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_05_01.jpg",
    cyberboard_r2_swiper_05_02:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_05_02.jpg",
    cyberboard_r2_swiper_05_03:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_05_03.jpg",
    cyberboard_r2_swiper_06_01:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_06_01.jpg",
    cyberboard_r2_swiper_06_02:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_06_02.jpg",
    cyberboard_r2_swiper_06_03:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_06_03.jpg",
    cyberboard_r2_swiper_06_04:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_06_04.jpg",
    cyberboard_r2_swiper_07_01:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_07_01.jpg",
    cyberboard_r2_swiper_07_02:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_07_02.jpg",
    cyberboard_r2_swiper_07_03:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_07_03.jpg",
    cyberboard_r2_swiper_07_04:
      ossDir + "cyberboard_r2/cyberboard_r2_swiper_07_04.jpg",

    back_to_the_future_r2_01_cn:
      ossDir + "back_to_the_future_r2/back_to_the_future_r2_01_cn.jpg",
    back_to_the_future_r2_01_cn_mobile:
      ossDir + "back_to_the_future_r2/back_to_the_future_r2_01_cn_mobile.png",
    back_to_the_future_r2_01_en:
      ossDir + "back_to_the_future_r2/back_to_the_future_r2_01_en.jpg",
    back_to_the_future_r2_01_en_mobile:
      ossDir + "back_to_the_future_r2/back_to_the_future_r2_01_en_mobile.jpg",
    back_to_the_future_r2_02:
      ossDir + "back_to_the_future_r2/back_to_the_future_r2_02.jpg",
    back_to_the_future_r2_03:
      ossDir + "back_to_the_future_r2/back_to_the_future_r2_03.jpg",
    back_to_the_future_r2_04:
      ossDir + "back_to_the_future_r2/back_to_the_future_r2_04.jpg",
    back_to_the_future_r2_05:
      ossDir + "back_to_the_future_r2/back_to_the_future_r2_05.png",
    back_to_the_future_r2_line:
      ossDir + "back_to_the_future_r2/back_to_the_future_r2_line.png",
    back_to_the_future_r2_swiper_01_01:
      ossDir + "back_to_the_future_r2/back_to_the_future_r2_swiper_01_01.jpg",
    back_to_the_future_r2_swiper_01_02:
      ossDir + "back_to_the_future_r2/back_to_the_future_r2_swiper_01_02.jpg",
    back_to_the_future_r2_swiper_01_03:
      ossDir + "back_to_the_future_r2/back_to_the_future_r2_swiper_01_03.jpg",

    emergence_keycaps_01_cn:
      ossDir + "emergence_keycaps/emergence_keycaps_01_cn.png",
    emergence_keycaps_01_cn_mobile:
      ossDir + "emergence_keycaps/emergence_keycaps_01_cn_mobile.png",
    emergence_keycaps_01_en:
      ossDir + "emergence_keycaps/emergence_keycaps_01_en.png",
    emergence_keycaps_01_en_mobile:
      ossDir + "emergence_keycaps/emergence_keycaps_01_en_mobile.jpg",
    emergence_keycaps_02: ossDir + "emergence_keycaps/emergence_keycaps_02.jpg",
    emergence_keycaps_03: ossDir + "emergence_keycaps/emergence_keycaps_03.jpg",
    emergence_keycaps_04: ossDir + "emergence_keycaps/emergence_keycaps_04.jpg",
    emergence_keycaps_05: ossDir + "emergence_keycaps/emergence_keycaps_05.png",
    emergence_keycaps_swiper_01_01:
      ossDir + "emergence_keycaps/emergence_keycaps_swiper_01_01.jpg",
    emergence_keycaps_swiper_01_02:
      ossDir + "emergence_keycaps/emergence_keycaps_swiper_01_02.jpg",
    emergence_keycaps_swiper_01_03:
      ossDir + "emergence_keycaps/emergence_keycaps_swiper_01_03.jpg",
    emergence_keycaps_swiper_01_04:
      ossDir + "emergence_keycaps/emergence_keycaps_swiper_01_04.jpg",
    emergence_keycaps_swiper_01_05:
      ossDir + "emergence_keycaps/emergence_keycaps_swiper_01_05.jpg",
    emergence_keycaps_swiper_01_06:
      ossDir + "emergence_keycaps/emergence_keycaps_swiper_01_06.jpg",

    the_chimney_cat_kv: ossDir + "the_chimney_cat/the_chimney_cat_kv.png",
    the_chimney_cat_kv_mobile:
      ossDir + "the_chimney_cat/the_chimney_cat_kv_mobile.png",
    the_chimney_cat_01: ossDir + "the_chimney_cat/the_chimney_cat_01.png",
    the_chimney_cat_02: ossDir + "the_chimney_cat/the_chimney_cat_02.png",
    the_chimney_cat_03: ossDir + "the_chimney_cat/the_chimney_cat_03.png",
    the_chimney_cat_04: ossDir + "the_chimney_cat/the_chimney_cat_04.png",

    time_machine_kv: ossDir + "time_machine/time_machine_kv.jpg",
    time_machine_01: ossDir + "time_machine/time_machine_01.png",
    time_machine_02: ossDir + "time_machine/time_machine_02.jpg",
    time_machine_03: ossDir + "time_machine/time_machine_03.png",
    time_machine_04: ossDir + "time_machine/time_machine_04.jpg",

    icy_silver_switch_title:
      ossDir + "icy_silver_switch/icy_silver_switch_title.jpg",
    icy_silver_switch_01: ossDir + "icy_silver_switch/icy_silver_switch_01.jpg",
    icy_silver_switch_02: ossDir + "icy_silver_switch/icy_silver_switch_02.jpg",
    icy_silver_switch_03: ossDir + "icy_silver_switch/icy_silver_switch_03.jpg",
    icy_silver_switch_03_line_01:
      ossDir + "icy_silver_switch/icy_silver_switch_03_line_01.png",
    icy_silver_switch_03_line_02:
      ossDir + "icy_silver_switch/icy_silver_switch_03_line_02.png",
    icy_silver_switch_04: ossDir + "icy_silver_switch/icy_silver_switch_04.jpg",
    icy_silver_switch_04_line_01:
      ossDir + "icy_silver_switch/icy_silver_switch_04_line_01.png",
    icy_silver_switch_04_line_02:
      ossDir + "icy_silver_switch/icy_silver_switch_04_line_02.png",
    icy_silver_switch_05: ossDir + "icy_silver_switch/icy_silver_switch_05.jpg",
    icy_silver_switch_06: ossDir + "icy_silver_switch/icy_silver_switch_06.jpg",

    glacier_keycap_set_01:
      ossDir + "glacier_keycap_set/glacier_keycap_set_01.jpg",
    glacier_keycap_set_02:
      ossDir + "glacier_keycap_set/glacier_keycap_set_02.jpg",
    glacier_keycap_set_03:
      ossDir + "glacier_keycap_set/glacier_keycap_set_03.jpg",
    glacier_keycap_set_04:
      ossDir + "glacier_keycap_set/glacier_keycap_set_04.png",
    glacier_keycap_set_05_01:
      ossDir + "glacier_keycap_set/glacier_keycap_set_05_01.png",
    glacier_keycap_set_05_02:
      ossDir + "glacier_keycap_set/glacier_keycap_set_05_02.png",
    glacier_keycap_set_05_03:
      ossDir + "glacier_keycap_set/glacier_keycap_set_05_03.png",
    glacier_keycap_set_05_04:
      ossDir + "glacier_keycap_set/glacier_keycap_set_05_04.png",
    glacier_keycap_set_05_05:
      ossDir + "glacier_keycap_set/glacier_keycap_set_05_05.png",
    glacier_keycap_set_05_06:
      ossDir + "glacier_keycap_set/glacier_keycap_set_05_06.png",
    glacier_keycap_set_05_07:
      ossDir + "glacier_keycap_set/glacier_keycap_set_05_07.png",
    glacier_keycap_set_05_08:
      ossDir + "glacier_keycap_set/glacier_keycap_set_05_08.png",
    glacier_keycap_set_icon_01:
      ossDir + "glacier_keycap_set/glacier_keycap_set_icon_01.png",
    glacier_keycap_set_icon_02:
      ossDir + "glacier_keycap_set/glacier_keycap_set_icon_02.png",
    glacier_keycap_set_icon_03:
      ossDir + "glacier_keycap_set/glacier_keycap_set_icon_03.png",
    glacier_keycap_set_line:
      ossDir + "glacier_keycap_set/glacier_keycap_set_line.png",

    angry_miao_021c_kv: ossDir + "angry_miao_021c/angry_miao_021c_kv.png",
    angry_miao_021c_kv_mobile:
      ossDir + "angry_miao_021c/angry_miao_021c_kv_mobile.png",
    angry_miao_021c_01: ossDir + "angry_miao_021c/angry_miao_021c_01.png",
    angry_miao_021c_02: ossDir + "angry_miao_021c/angry_miao_021c_02.png",
    angry_miao_021c_03: ossDir + "angry_miao_021c/angry_miao_021c_03.png",

    am_hatsu_01: ossDir + "am_hatsu/am_hatsu_01.jpg",
    am_hatsu_01_mobile: ossDir + "am_hatsu/am_hatsu_01_mobile.jpg",
    am_hatsu_02: ossDir + "am_hatsu/am_hatsu_02.jpg",
    am_hatsu_03: ossDir + "am_hatsu/am_hatsu_03.jpg",
    am_hatsu_04: ossDir + "am_hatsu/am_hatsu_04.jpg",
    am_hatsu_05: ossDir + "am_hatsu/am_hatsu_05.jpg",
    am_hatsu_06: ossDir + "am_hatsu/am_hatsu_06.mp4",
    am_hatsu_icon_01: ossDir + "am_hatsu/am_hatsu_icon_01.png",
    am_hatsu_icon_02: ossDir + "am_hatsu/am_hatsu_icon_02.png",
    am_hatsu_icon_03: ossDir + "am_hatsu/am_hatsu_icon_03.png",
    am_hatsu_number: ossDir + "am_hatsu/am_hatsu_number.png",

    am_hatsu_battleship_01_cn:
      ossDir + "am_hatsu_battleship/am_hatsu_battleship_01_cn.jpg",
    am_hatsu_battleship_01_en:
      ossDir + "am_hatsu_battleship/am_hatsu_battleship_01_en.jpg",
    am_hatsu_battleship_01_mobile_cn:
      ossDir + "am_hatsu_battleship/am_hatsu_battleship_01_mobile_cn.jpg",
    am_hatsu_battleship_01_mobile_en:
      ossDir + "am_hatsu_battleship/am_hatsu_battleship_01_mobile_en.jpg",
    am_hatsu_battleship_02:
      ossDir + "am_hatsu_battleship/am_hatsu_battleship_02.jpg",
    am_hatsu_battleship_03:
      ossDir + "am_hatsu_battleship/am_hatsu_battleship_03.jpg",
    am_hatsu_battleship_04:
      ossDir + "am_hatsu_battleship/am_hatsu_battleship_04.jpg",
    am_hatsu_battleship_05:
      ossDir + "am_hatsu_battleship/am_hatsu_battleship_05.jpg",
    am_hatsu_battleship_06:
      ossDir + "am_hatsu_battleship/am_hatsu_battleship_06.jpg",
    am_hatsu_battleship_icon_01:
      ossDir + "am_hatsu_battleship/am_hatsu_battleship_icon_01.png",
    am_hatsu_battleship_icon_02:
      ossDir + "am_hatsu_battleship/am_hatsu_battleship_icon_02.png",
    am_hatsu_battleship_icon_03:
      ossDir + "am_hatsu_battleship/am_hatsu_battleship_icon_03.png",

    xmas_01_cn: ossDir + "cyberboard_xmas/xmas_01_cn.mp4",
    xmas_01_en: ossDir + "cyberboard_xmas/xmas_01_en.mp4",
    xmas_02_cn: ossDir + "cyberboard_xmas/xmas_02_cn.mp4",
    xmas_02_en: ossDir + "cyberboard_xmas/xmas_02_en.mp4",
    xmas_03___: ossDir + "cyberboard_xmas/xmas_03_cn.png",
    xmas_03_cn: ossDir + "cyberboard_xmas/xmas_03_cn.mp4",
    xmas_03_en: ossDir + "cyberboard_xmas/xmas_03_en.mp4",
    xmas_04_cn: ossDir + "cyberboard_xmas/xmas_04_cn.mp4",
    xmas_04_en: ossDir + "cyberboard_xmas/xmas_04_en.mp4",
    xmas_05_cn: ossDir + "cyberboard_xmas/xmas_05_cn.png",
    xmas_05_en: ossDir + "cyberboard_xmas/xmas_05_en.png",
    xmas_06_cn: ossDir + "cyberboard_xmas/xmas_06_cn.mp4",
    xmas_06_en: ossDir + "cyberboard_xmas/xmas_06_en.mp4",
    xmas_07_cn: ossDir + "cyberboard_xmas/xmas_07_cn.jpg",
    xmas_07_en: ossDir + "cyberboard_xmas/xmas_07_en.mp4",
    xmas_08_cn: ossDir + "cyberboard_xmas/xmas_08_cn.png",
    xmas_08_en: ossDir + "cyberboard_xmas/xmas_08_en.png",
    xmas_09_cn: ossDir + "cyberboard_xmas/xmas_09_cn.png",
    xmas_09_en: ossDir + "cyberboard_xmas/xmas_09_en.png",
    xmas_10_cn: ossDir + "cyberboard_xmas/xmas_10_cn.png",
    xmas_10_en: ossDir + "cyberboard_xmas/xmas_10_en.png",
    xmas_11_cn: ossDir + "cyberboard_xmas/xmas_11_cn.png",
    xmas_11_en: ossDir + "cyberboard_xmas/xmas_11_en.png",

    charge_01: ossDir + "cybercharge/charge_01.mp4",
    charge_02: ossDir + "cybercharge/charge_02.png",
    charge_03: ossDir + "cybercharge/charge_03.png",
    charge_03_m: ossDir + "cybercharge/charge_03_m.png",
    charge_04: ossDir + "cybercharge/charge_04.png",
    charge_05: ossDir + "cybercharge/charge_05.png",
    charge_06: ossDir + "cybercharge/charge_06.png",
    charge_07: ossDir + "cybercharge/charge_07.mp4",
    charge_08: ossDir + "cybercharge/charge_08.png",
    charge_09: ossDir + "cybercharge/charge_09.png",
    charge_10_cn: ossDir + "cybercharge/charge_10_cn.png",
    charge_10_en: ossDir + "cybercharge/charge_10_en.png",
    charge_11: ossDir + "cybercharge/charge_11.png",
    charge_12: ossDir + "cybercharge/charge_12.png",
    charge_13: ossDir + "cybercharge/charge_13.png",
    charge_14: ossDir + "cybercharge/charge_14.png",
    charge_15: ossDir + "cybercharge/charge_15.png",
    charge_icon_01: ossDir + "cybercharge/charge_icon_01.png",
    charge_icon_02: ossDir + "cybercharge/charge_icon_02.png",
    charge_icon_03: ossDir + "cybercharge/charge_icon_03.png",
    charge_icon_04: ossDir + "cybercharge/charge_icon_04.png",
    charge_icon_05: ossDir + "cybercharge/charge_icon_05.png",
    charge_icon_06: ossDir + "cybercharge/charge_icon_06.png",

    mat_01: ossDir + "cybermat/mat_01.mp4",
    mat_02: ossDir + "cybermat/mat_02.jpg",
    mat_02_cn: ossDir + "cybermat/mat_02_cn.png",
    mat_02_en: ossDir + "cybermat/mat_02_en.png",
    mat_03: ossDir + "cybermat/mat_03.png",
    mat_04: ossDir + "cybermat/mat_04.mp4",
    mat_05: ossDir + "cybermat/mat_05.mp4",
    mat_06: ossDir + "cybermat/mat_06.mp4",
    mat_07: ossDir + "cybermat/mat_07.png",
    mat_07_cn: ossDir + "cybermat/mat_07_cn.png",
    mat_07_en: ossDir + "cybermat/mat_07_en.png",

    board_01_01: ossDir + "cyberboard/board_01_01.png",
    board_01_02: ossDir + "cyberboard/board_01_02.mp4",
    board_01_03: ossDir + "cyberboard/board_01_03.mp4",
    board_01_04: ossDir + "cyberboard/board_01_04.png",
    board_02: ossDir + "cyberboard/board_02.png",
    board_03: ossDir + "cyberboard/board_03.png",
    board_04: ossDir + "cyberboard/board_04.mp4",
    board_05: ossDir + "cyberboard/board_05.png",
    board_06: ossDir + "cyberboard/board_06.jpg",
    board_07: ossDir + "cyberboard/board_07.mp4",
    board_08: ossDir + "cyberboard/board_08.mp4",
    board_10: ossDir + "cyberboard/board_10.jpg",
    board_11: ossDir + "cyberboard/board_11.jpg",
    board_12: ossDir + "cyberboard/board_12.mp4",
    board_13: ossDir + "cyberboard/board_13.png",
    board_14: ossDir + "cyberboard/board_14.mp4",
    board_15: ossDir + "cyberboard/board_15.png",
    board_16: ossDir + "cyberboard/board_16.mp4",
    board_17: ossDir + "cyberboard/board_17.png",
    board_18: ossDir + "cyberboard/board_18.png",
    board_19_01: ossDir + "cyberboard/board_19_01.jpg",
    board_19_02: ossDir + "cyberboard/board_19_02.png",
    board_20: ossDir + "cyberboard/board_20.mp4",
    board_21: ossDir + "cyberboard/board_21.jpg",

    coin_01: ossDir + "cybercoin/coin_01.jpg",
    coin_01_mobile: ossDir + "cybercoin/coin_01_mobile.jpg",
    coin_02: ossDir + "cybercoin/coin_02.mp4",
    coin_03: ossDir + "cybercoin/coin_03.mp4",
    coin_04: ossDir + "cybercoin/coin_04.mp4",
    coin_05: ossDir + "cybercoin/coin_05.png",
    coin_06_cn: ossDir + "cybercoin/coin_06_cn.png",
    coin_06_en: ossDir + "cybercoin/coin_06_en.png",
    coin_07: ossDir + "cybercoin/coin_07.jpg",

    switch_01: ossDir + "cyberswitch/switch_01.png",
    switch_02: ossDir + "cyberswitch/switch_02.mp4",
    switch_03: ossDir + "cyberswitch/switch_03.png",
    switch_04: ossDir + "cyberswitch/switch_04.png",
    switch_05_01: ossDir + "cyberswitch/switch_05_01.png",
    switch_05_02: ossDir + "cyberswitch/switch_05_02.png",
    switch_05_cn: ossDir + "cyberswitch/switch_05_cn.png",
    switch_05_en: ossDir + "cyberswitch/switch_05_en.png",
    switch_06: ossDir + "cyberswitch/switch_06.mp4",
    switch_07: ossDir + "cyberswitch/switch_07.mp4",
    switch_08: ossDir + "cyberswitch/switch_08.png",
    switch_09: ossDir + "cyberswitch/switch_09.mp4",
    switch_09_cn: ossDir + "cyberswitch/switch_09_cn.png",
    switch_09_en: ossDir + "cyberswitch/switch_09_en.png",
    switch_10: ossDir + "cyberswitch/switch_10.jpeg",

    ext_01_01: ossDir + "ext/ext_01_01.png",
    ext_01_02: ossDir + "ext/ext_01_02.png",
    ext_01_03: ossDir + "ext/ext_01_03.png",
    ext_01_04: ossDir + "ext/ext_01_04.png",
    ext_01_05: ossDir + "ext/ext_01_05.png",
    ext_01_06: ossDir + "ext/ext_01_06.png",
    ext_02_01: ossDir + "ext/ext_02_01.png",
    ext_02_02: ossDir + "ext/ext_02_02.png",
    ext_02_03: ossDir + "ext/ext_02_03.png",
    ext_02_04: ossDir + "ext/ext_02_04.png",
    ext_02_05: ossDir + "ext/ext_02_05.png",
    ext_02_06: ossDir + "ext/ext_02_06.png",
    ext_03_01: ossDir + "ext/ext_03_01.png",
    ext_03_02: ossDir + "ext/ext_03_02.png",
    ext_03_03: ossDir + "ext/ext_03_03.png",
    ext_03_04: ossDir + "ext/ext_03_04.png",
    ext_03_05: ossDir + "ext/ext_03_05.png",
    ext_03_06: ossDir + "ext/ext_03_06.png",
    ext_04_01: ossDir + "ext/ext_04_01.png",
    ext_04_02: ossDir + "ext/ext_04_02.png",
    ext_04_03: ossDir + "ext/ext_04_03.png",
    ext_04_04: ossDir + "ext/ext_04_04.png",
    ext_04_05: ossDir + "ext/ext_04_05.png",
    ext_04_06: ossDir + "ext/ext_04_06.png",
    ext_05: ossDir + "ext/ext_05.jpg",
    ext_banner_cn: ossDir + "ext/ext_banner_cn.png",
    ext_banner_en: ossDir + "ext/ext_banner_en.png",
    ext_size_cn: ossDir + "ext/ext_size_cn.svg",
    ext_size_en: ossDir + "ext/ext_size_en.png",

    about_01: ossDir + "about/about_01.jpg",
    about_person_01: ossDir + "about/about_person_01.png",
    about_person_02: ossDir + "about/about_person_02.png",
    about_person_03: ossDir + "about/about_person_04.png",
    about_swiper_01: ossDir + "about/about_swiper_01.jpg",
    about_swiper_02: ossDir + "about/about_swiper_02.jpg",
    about_swiper_03: ossDir + "about/about_swiper_03.jpg",

    qr_code_dy: ossDir + "qr_code/qr_code_dy.png",
    qr_code_xhs: ossDir + "qr_code/qr_code_xhs.png",
    qr_code_am_emptiness_charger:
      ossDir + "qr_code/qr_code_am_emptiness_charger.png",
    qr_code_at: ossDir + "qr_code/qr_code_at.png",
    qr_code_am_neon_80: ossDir + "qr_code/qr_code_am_neon_80.png",
    qr_code_am_rgb_65: ossDir + "qr_code/qr_code_am_rgb_65.png",
    qr_code_am_afa_r2: ossDir + "qr_code/qr_code_am_afa_r2.png",
    qr_code_am_compact_touch_split:
      ossDir + "qr_code/qr_code_am_compact_touch_split.png",
    qr_code_carpio_am_editon: ossDir + "qr_code/qr_code_carpio_am_editon.png",
    qr_code_am_65_less: ossDir + "qr_code/qr_code_am_65_less.png",
    qr_code_am_afa: ossDir + "qr_code/qr_code_am_afa.png",
    qr_code_am_hatsu: ossDir + "qr_code/qr_code_am_hatsu.png",
    qr_code_amtoys: ossDir + "qr_code/qr_code_amtoys.png",
    qr_code_angry_miao_021c: ossDir + "qr_code/qr_code_angry_miao_021c.png",
    qr_code_artisan: ossDir + "qr_code/qr_code_artisan.png",
    qr_code_buy: ossDir + "qr_code/qr_code_buy.png",
    qr_code_cyberblade: ossDir + "qr_code/qr_code_cyberblade.png",
    qr_code_cyberboard: ossDir + "qr_code/qr_code_cyberboard.png",
    qr_code_cyberboard_gb: ossDir + "qr_code/qr_code_cyberboard_gb.png",
    qr_code_cyberboard_r2: ossDir + "qr_code/qr_code_cyberboard_r2.png",
    qr_code_cyberboard_r3: ossDir + "qr_code/qr_code_cyberboard_r3.png",
    qr_code_cyberboard_terminal:
      ossDir + "qr_code/qr_code_cyberboard_terminal.png",
    qr_code_cybercharge: ossDir + "qr_code/qr_code_cybercharge.png",
    qr_code_cybermat: ossDir + "qr_code/qr_code_cybermat.png",
    qr_code_discord: ossDir + "qr_code/qr_code_discord.png",
    qr_code_ext: ossDir + "qr_code/qr_code_ext.png",
    qr_code_instagram: ossDir + "qr_code/qr_code_instagram.png",
    qr_code_twitter_ja: ossDir + "qr_code/qr_code_twitter_ja.png",
    qr_code_the_chimney_cat: ossDir + "qr_code/qr_code_the_chimney_cat.png",
    qr_code_time_machine: ossDir + "qr_code/qr_code_time_machine.png",
    qr_code_back_to_the_future_r2:
      ossDir + "qr_code/qr_code_back_to_the_future_r2.png",
    qr_code_qq_pd: ossDir + "qr_code/qr_code_qq_pd.png",
    qr_code_wechat: ossDir + "qr_code/qr_code_wechat.png",
    qr_code_wechat_club: ossDir + "qr_code/qr_code_wechat_club.png",
    qr_code_wechat_gzh: ossDir + "qr_code/qr_code_wechat_gzh.png",
    qr_code_weibo: ossDir + "qr_code/qr_code_weibo.png",

    pdf_am_rgb_65:
      ossDir +
      "pdf/AMRGB65UserManual%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf",
    pdf_am_relic_80:
      ossDir +
      "pdf/AMRelic80UserManual%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf",
    pdf_am_65_less:
      ossDir +
      "pdf/AM65LessUserManual%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf",
    pdf_am_65_less_r2:
      ossDir +
      "pdf/AM65LessR2UserManual%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf",
    pdf_am_afa:
      ossDir +
      "pdf/AMAFAR1%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8CUserManual.pdf",
    pdf_am_afa_build:
      ossDir + "pdf/AMAFAR1%E6%8B%86%E8%A3%85%E8%AF%B4%E6%98%8EBuildGuide.pdf",
    pdf_am_afa_r2:
      ossDir +
      "pdf/AMAFAR2%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8CUserManual.pdf",
    pdf_am_afa_build_r2:
      ossDir + "pdf/AMAFAR2%E6%8B%86%E8%A3%85%E8%AF%B4%E6%98%8EBuildGuide.pdf",
    pdf_am_hatsu:
      ossDir +
      "pdf/AMHATSUUserManual%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf",
    pdf_cyberblade:
      ossDir +
      "pdf/CYBERBLADEUserManual%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf",
    pdf_cyberblade_base_edition:
      ossDir +
      "pdf/CYBERBLADEBaseEdition%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf",
    pdf_cyberboard:
      ossDir +
      "pdf/CYBERBOARDUserManual%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf",
    pdf_cyberboard_r2_r3:
      ossDir +
      "pdf/CYBERBOARD_R2%26R3UserManual%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf",
    pdf_cyberboard_r4:
      ossDir +
      "pdf/CYBERBOARDR4UserManual%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf",
    pdf_cyberboard_xmas:
      ossDir +
      "pdf/CYBERBOARDXMASUserManual%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf",
    pdf_cyberboard_terminal:
      ossDir +
      "pdf/CYBERBOARD_TerminalUserManual%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf",
    pdf_cybercharge:
      ossDir +
      "pdf/CYBERCHARGEUserManual%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf",
    pdf_cybercoin:
      ossDir +
      "pdf/CYBERCOINUserManual%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf",
    pdf_cybermat_r2:
      ossDir +
      "pdf/CYBERMATR2UserManual%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf",
    pdf_cyberboardR2_5:
      ossDir +
      "pdf/CYBERBOARD_R2.5UserManual%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf",
    pdf_black_diamond_75:
      ossDir +
      "pdf/Blackdiamond75UserManual%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf"
  },
  url: {
    amazon_cyberblade:
      "https://www.amazon.com/Angry-Bluetooth-Cancelling-Headphones-Compatible/dp/B0BG4Z2W89",

    logo: "https://www.angrymiao.com/logo.png",
    mall: "https://mall.angrymiao.com/",
    m: "https://m.angrymiao.com/",
    diy: "https://diy.angrymiao.com/",
    diy_publish: "https://diy.angrymiao.com/publish/",
    diy_library: "https://diy.angrymiao.com/library/",
    diy_hatsu: "https://diy.angrymiao.com/keyboard/hatsu/",
    diy_am_afa: "https://diy.angrymiao.com/keyboard/am-afa/",
    diy_am_65_less: "https://diy.angrymiao.com/keyboard/65-less/",
    qq:
      "https://qm.qq.com/cgi-bin/qm/qr?k=KX0oHnpt4UnywDer9AnXXGo5ljmk4NMT&authKey=ThMfIAZreI/NXDaq+mA/0RldpcRBo9AzSqOv4e3WKYRGAxxvL1PxoFo9cuJalvAt&noverify=0",
    weibo: "https://weibo.com/u/7358663381",
    bilibili: "https://space.bilibili.com/20467249",
    discord: "https://discord.gg/Xre4H4c",
    discord_ja: "https://discord.gg/Xre4H4c",
    discord_am_65_less: "https://discord.gg/ZHXrgrPF6c",
    discord_am_afa: "https://discord.gg/BKNcnP6Tkj",
    discord_cyberblade: "https://discord.gg/8xfc8cFRbd",
    discordWidget:
      "https://discord.com/widget?id=688994974754406404&theme=dark",
    facebook: "https://www.facebook.com/angrymiao",
    instagram: "https://www.instagram.com/angry.miao/",
    youtube: "https://www.youtube.com/@angry_miao",
    youtube_ja: "https://www.youtube.com/channel/UCYzjND_kZFfptA71LwcY8Tw/",
    twitter: "https://twitter.com/Angry_Miao",
    twitter_ja: "https://twitter.com/AngryMiao_Japan",

    amtoys: "https://zhongchou.modian.com/item/101970.html",

    subscribe: "https://shopify.angrymiao.com/pages/subscribe",
    cart: "https://shopify.angrymiao.com/cart",
    contact_us: "https://shopify.angrymiao.com/pages/contact-us",

    copyright: "https://beian.miit.gov.cn/"
  }
};

export default {
  namespaced: true,
  state
};
